/* BLADE: source/riepilogo.blade.php */
/* SCSS:  source/_assets/sass/partials/_riepilogo.scss */
/* Called: source/_assets/partials/_styles.scss */

@keyframes bounce {
    0% {
        top: 52%;
    }
    10% {
        top: 37%;
    }
    20% {
        top: 65%;
    }
    30% {
        top: 37%;
    }
    40% {
        top: 65%;
    }
    50% {
        top: 37%;
    }
    60% {
        top: 65%;
    }
    70% {
        top: 37%;
    }
    80% {
        top: 65%;
    }
    90% {
        top: 37%;
    }
    100% {
        top: 52%;
    }
}

.riepilogo {
    @include rem ((margin-top:2px));
    border-top: 1px solid $gray-bg;

    #navigatore {
        background-color: $orange;
        border-top-left-radius: 60px;
        border-top-right-radius: 60px;
        bottom: 0;
        cursor: pointer;
        display: block;
        height: 50px;
        left: 50%;
        position: fixed;
        transform: translateX(-50%);
        width: 100px;
        z-index: 999;

        &:hover {
            text-decoration: none;
        }

        &::after {
            animation-duration: 8s;
            animation-name: bounce;
            color: white;
            content: "";
            font-family: "FontAwesome", sans-serif;
            font-size: 4.75rem;
            left: 50%;
            line-height: 4.75rem;
            position: absolute;
            text-decoration: none;
            top: 52%;
            transform: translateX(-50%) translateY(-50%);
        }
        &.navigatore--top::after {
            content: "";
        }

        @include media-breakpoint-down (md) {
            display: none;
        }
    }

    &__inner {
        @include rem ((padding: 0px 20px,));
        @include media-breakpoint-down (sm) {
            @include rem ((padding: 0px 5px,));
        }
        .radio__label {
            @include rem ((margin-right:25px))
        }
    }

    .edit-link {
        text-align: right;
        color: $gray;
        font-weight: $font-weight-medium;
        @include rem ((margin: 10px 0px 0px,));
        @include media-breakpoint-down (sm) {
            @include rem ((font-size: 14px,));
        }

        a {
            color: $orange;
            @include rem ((margin-left: 10px,));
            @include media-breakpoint-down (sm) {
                @include rem ((font-size: 14px,));
                display: block;
            }
        }
    }

    .mandatory {
        text-align: left;
        font-weight: $font-weight-light;
        @include rem((font-size: 13px,));
    }

    .title-icon {
        font-weight: $font-weight-medium;
        border-bottom: 1px solid $gray-form;
        color: $blue;
        position: relative;
        @include rem ((margin:60px 0px 20px, font-size: 16px, line-height: 35px, padding-left: 40px,));
        @include media-breakpoint-down (sm) {
            @include rem ((font-size: 14px, line-height: 25px, padding-left: 30px,));
        }

        .icon {
            display: block;
            width: 35px;
            height: 35px;
            position: absolute;
            top: -5px;
            left: 0px;

            @include media-breakpoint-down (sm) {
                width: 25px;
                height: 25px;
            }

            &--casa {
                background: url($images-path + '/sezioni/casa.svg') bottom left no-repeat;
            }
            &--carrello {
                background: url($images-path + '/sezioni/carrello.svg') bottom left no-repeat;
            }
            &--user {
                background: url($images-path + '/sezioni/user.svg') bottom left no-repeat;
            }
            &--agente {
                background: url($images-path + '/sezioni/agente.svg') bottom left no-repeat;
            }
            &--documenti {
                background: url($images-path + '/sezioni/documenti.svg') bottom left no-repeat;
            }
            &--informative {
                background: url($images-path + '/sezioni/informative.svg') bottom left no-repeat;
            }
        }

        .accordion-chevron {
            position: absolute;
            color: $orange;
            right: 10px;
            top: 50%;
            transform: translate(-50%, -50%);
            @include media-breakpoint-down (sm) {
                right: 0px;
            }
        }

        @extend .clearfix;
    }

    #accordion_informative .panel-title a {
        @include rem ((
                font-size: 15px,
                padding-right: 14px
        ));
    }

    .info-desktop {
        display: none;
        @include media-breakpoint-up (md) {
            display: inline;
        }
    }
    .info-mobile {
        display: none;
        @include rem((font-size: 13px,));
        @include media-breakpoint-down (sm) {
            display: block;
        }
    }

    .container:first-child .title-icon {
        @include rem ((margin-top:25px,));
    }

    &__garanzia {
        display: block;
        width: 100%;
        background-color: $gray-bg;
        @include rem ((padding: 0px 20px, margin-bottom: 10px,));
        @include media-breakpoint-down (sm) {
            @include rem ((padding: 0px 10px,));
        }

        .item {
            display: block;
            float: left;
            width: 70%;
            font-size: 12px;
            line-height: 50px;
            font-weight: $font-weight-semi-bold;
            color: $orange;
            text-transform: uppercase;
        }
        .price {
            display: block;
            float: right;
            width: 30%;
            font-size: 16px;
            line-height: 50px;
            font-weight: $font-weight-light;
            text-align: right;
            color: $gray;
        }
        @extend .clearfix;
    }

    &__mappa {
        display: block;
        width: 100%;
        height: 250px;
        background-size: cover;
        background-image: url($images-path + "/riepilogo/mappa.jpg");
        background-position: center;
        position: relative;
        @include rem ((margin-top: -20px, margin-bottom: 20px,));

        &__inner {
            width: 70%;
            background: white none repeat scroll 0 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @include rem ((padding: 40px 50px,));

            @include media-breakpoint-down (sm) {
                width: 90%;
                @include rem ((padding: 15px 20px,));
            }
            @include media-breakpoint-only (md) {
                width: 80%;
                @include rem ((padding: 25px 40px,));
            }

            .image {
                display: block;
                float: left;
                width: 75px;
                @include media-breakpoint-down (md) {
                    width: 100%;
                    text-align: center;
                }
            }
            .text {
                display: block;
                float: right;
                width: calc(100% - 75px);
                @include media-breakpoint-down (md) {
                    width: 100%;
                    text-align: center;
                }
            }
            @extend .clearfix;

            h4 {
                color: $blue;
                font-weight: $font-weight-medium;
                @include rem ((font-size: 18px,));
                @include media-breakpoint-down (sm) {
                    @include rem ((font-size: 14px, line-height:20px,));
                }
            }
            p {
                color: $blue;
                font-weight: $font-weight-regular;
                @include rem ((font-size: 14px,));
                @include media-breakpoint-down (sm) {
                    @include rem ((font-size: 13px, line-height:20px,));
                }
            }
        }
    }

    .paperclip {
        a {
            font-weight: $font-weight-light;
            color: $gray;
            text-decoration: underline;
            display: inline-block;
            position: relative;
            @include rem ((font-size: 16px, padding-left: 35px,));
            @include media-breakpoint-down (sm) {
                @include rem ((font-size: 14px,));
            }
            i {
                position: absolute;
                left: 3px;
                text-decoration: none;
                -ms-transform: rotate(90deg);
                -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
                @include rem ((font-size: 24px,));
            }
            span {
            }
        }
    }

    .scroller {
        position: relative;
        display: none;
        @include rem ((margin: 60px 0px 0px,));

        &--active {
            display: block;
        }

        &__item {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .fa-circle {
                color: $orange;
            }
        }

        &__anchor {
            position: relative;
            &__inner {
                position: absolute;
                top: 50px;
            }
        }

    }
}

.modal__salva-preventivo {
    p {
        text-align: center;
    }
    .btn {
        min-width: 200px;
    }
}