/* BLADE: source/_partials/forms/field-data.blade.php */
/* SCSS:  source/_assets/sass/partials/base/forms/_field-data.scss */

.field-data {
  @include rem((margin-bottom: 20px, margin-top: 3px, padding-left: 8px));
  @include media-breakpoint-down (sm) { @include rem((margin-bottom: 15px)); }
  &--label {
    color: $orange; font-weight: $font-weight-semi-bold; text-transform: uppercase;
    @include rem((font-size: 12px,));
    //@include media-breakpoint-down (lg) { @include rem((font-size: 11px)); }
    &--info{
      @include rem((font-size: 16px,));
    }
  }
  &--content {
    overflow: hidden; white-space: nowrap;
    @include rem((font-size: 16px,));
    @include media-breakpoint-down (lg) { @include rem((font-size: 14px)); }
  }
}