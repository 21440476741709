/* BLADE: source/premio.blade.php */
/* SCSS:  source/_assets/sass/partials/_premio.scss */
/* Called: source/_assets/partials/_styles.scss */

@import "modules/premio/card";
@import "modules/premio/mappa";
@import "modules/premio/dati";
@import "modules/premio/convenzionali";
@import "modules/premio/garanzie";
@import "modules/premio/quotazione";
@import "modules/premio/sconto";
@import "modules/premio/modal";
@import "modules/premio/range-slider";
@import "modules/premio/karte-slider";
@import "modules/premio/tab-package";
