body {
    margin: 0;
    color: $gray;
    font-family: $font-family-base;
    font-weight: $font-weight-light;
}

h1 {
    font-family: $font-family-base;
    font-weight: $font-weight-medium;
    font-size: $font-size-h1;
    line-height: $font-size-h1 + 0.25rem; // 0.25rem = 4px
}

h2 {
    font-family: $font-family-base;
    font-weight: $font-weight-medium;
    font-size: $font-size-h2;
    line-height: $font-size-h2 + 0.25rem;
}

h3 {
    font-family: $font-family-base;
    font-weight: $font-weight-medium;
    font-size: $font-size-h3;
    line-height: $font-size-h3 + 0.25rem;
}

h4 {
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
    font-size: $font-size-h4;
    line-height: $font-size-h4 + 0.25rem;
}
h5 {
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
    font-size: $font-size-h5;
    line-height: $font-size-h5 + 0.25rem;
    padding-top: 3px;
}

a {
    color: $blue;
    font-weight: $font-weight-medium;
    &:focus {
        outline: none;
        outline-offset: 0;
    }
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right;
}

.text-blue {
    color: $blue;
}

.text-orange {
    color: $orange;
}

.text-gray-dark {
    color: $gray-dark;
}

.push-by-mobile-nav {
    position: relative;
}

.back {
    @include rem((font-size: 13px, margin-top: 0,));
    display: block;
    font-weight: $font-weight-light;
    color: $gray;
}

.modal-backdrop {
    background-color: $blue;
}