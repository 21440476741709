/* BLADE: source/_partials/premio/sconto.blade.php */
/* SCSS:  source/_assets/sass/partials/modules/premio/_sconto.scss */

// source/_assets/sass/partials/modules/_sconto.scss
.sconto {
  background-color: rgba($gray-lighter, 0.55);
  @include rem((
          padding:20px,
          margin-top:20px,
  ));
  @include media-breakpoint-down(md) {
    .container {
      padding: 0;
    }
  }

  .input-label {
    display: none
  }

  h2 {
    background: none;
    color: $orange;
    margin: 0;
    display: block;
    position: relative;
    text-align: center;
    @include media-breakpoint-down(md) {
      @include rem((
              padding: 15px 0,
              font-size: 24px,
              line-height:28px,
      ));
    }
  }

  span {
    display: block;
    &.container {
      &:after {
        content: "";
        display: inline;
      }
    }
    span {
      display: inline-block;
      @include rem ((padding-left: 42px));
      position: relative;
      &.iconHome {
        &:before {
          content: "";
          position: absolute;
          top: 45%;
          left: 0;
          //background: url($images-path + '/icons/icon_sconto.png') right center no-repeat;
          width: 28px;
          height: 28px;
          transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
        }
      }
    }
  }

  &__box {
    border-radius: 0;
  }

}