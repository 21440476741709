/* BLADE: source/_partials/premio/quotazione.blade.php */
/* SCSS:  source/_assets/sass/partials/modules/premio/_quotazione.scss */

.quotazione {
  display: none;
  @include rem((margin-top: 90px,));

  &--active { display: block; }

  &__info {
    h3 {
      color: $blue; text-align: center;
      @include rem((margin-bottom: 0px,));
    }
    p {
      text-align: center;
      @include rem((margin-bottom: 5px,));

      strong { font-weight: $font-weight-bold; }
    }
  }

  &__divider { display: block; width: 30%; margin: 35px auto; }

  &__cartina {
    @include rem((margin: 20px 0,));
  }

  &__form {
    @include rem((margin: 30px,));

    .mandatory {
      text-align: left; font-weight: $font-weight-light;
      @include rem((font-size: 13px,));

      a { color: $gray; font-weight: $font-weight-light; text-decoration: underline; }
    }

    &__cta {
      p { text-align: center; }
      .btn { min-width: 200px; }
    }
  }

  &__save-mobile {
    display: none;
    @include media-breakpoint-down(md) { display: block; }

    .container {
      border-top: 1px solid $gray-lighter;
      @include rem((margin-top: 30px, padding-top: 30px,));
      .download {
        @include media-breakpoint-down(sm){ text-align: center; }
        a {
          @include rem((font-size: 18px, ));
          color: $orange;
          font-weight: $font-weight-light;
          &.not-active {
            pointer-events: none;
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
      }
    }

  }

}