/* BLADE: source/_partials/riepilogo/accordion.blade.php */
/* SCSS:  source/_assets/sass/partials/modules/riepilogo/_accordion.scss */

.panel {

    &-heading {
        background-color: $gray-bg;
    }
    &-title {
        @include rem ((padding: 10px 15px,));

        a {
            display: block;
            width: 100%;
            position: relative;
            color: $gray;
            font-weight: $font-weight-medium;
            text-decoration: none;
            @include rem ((font-size: 16px,));
            @include media-breakpoint-down (sm) {
                @include rem ((font-size: 12px,));
            }
        }
        .more-less {
            position: absolute;
            top: 50%;
            right: 0px;
            transform: translate(0, -50%);
            color: $orange;
        }
    }
    &-body {
        @include rem ((padding: 10px 15px,));
    }
}

#accordionconvenzionali {
    padding-bottom: 20px;
}