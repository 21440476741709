/* BLADE: source/_partials/landing/base/header.blade.php */
/* SCSS:  source/_assets/sass/partials/modules/landing/_corner-ribbon.scss */

.ribbon-wrapper-green {
    height: 64px;
    float: left;
    padding: 15px 20px 0px 20px;
    color: white;
    background: $green;
    font-family: $font-family-base;
    font-size: 42px;
    font-weight: bold;
    line-height: 30px;
    text-align: left;
    margin-top: 20px;
    @include media-breakpoint-down (xs) {
        @include rem((
                font-size:24px,
                margin-top:0,
                margin-bottom: 0,
        ));
    }
    p {
        font-weight: $font-weight-bold;
        font-size: 30px;
        display: inline-block;
        @include media-breakpoint-down (xs) {
            @include rem((
                    font-size:14px,
                    margin-bottom: 0,
            ));
        }
    }
}

.boxTriangle {
    float: left;
    width: 0;
    height: 0;
    border-top: 65px solid $green;
    border-right: 40px solid transparent;
    margin-top: 20px;
    margin-left: -1px;
    @include media-breakpoint-down (xs) {
        @include rem((
                margin-top:0,
        ));
    }
}