/* BLADE: source/_partials/premio/tab_garanzie.blade.php */
/* SCSS:  source/_assets/sass/partials/modules/premio/_tab-package.scss */

.container-tab {
    //padding: 0 60px;
    @include media-breakpoint-down(lg) {
        //padding: 0 60px;
    }
    @include media-breakpoint-down(md) {
        //padding: 60px;
    }
    @include media-breakpoint-down(sm) {
        padding: 15px;
    }

}

#productTab {
    &.nav-tabs {
        border-bottom: 6px solid $blue-dark;
        .nav-item {
            margin-bottom: 0;
            .nav-link {
                font-weight: bold;
                font-size: 24px;
                background: linear-gradient(white, $gray-form);
                border-top: 1px solid $gray-lighter;
                border-right: 1px solid $gray-lighter;
                border-left: 1px solid $gray-lighter;
                border-bottom: 0;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                color: $gray;
                padding-top: 15px;
                height: 100%;
                &.active {
                    border-top: 1px solid $blue-dark;
                    border-right: 1px solid $blue-dark;
                    border-left: 1px solid $blue-dark;
                    background: linear-gradient(to bottom, $blue, $blue-dark);
                    color: white;
                    height: 100%;
                    h4 {
                        .icon-famiglia-tab {
                            background: url($images-path + '/tab/icona-tab-1-white.png') top left no-repeat;
                        }
                        .icon-casa-tab {
                            background: url($images-path + '/tab/icona-tab-2-white.png') top left no-repeat;
                        }
                        .icon-personalizzabile-tab {
                            background: url($images-path + '/tab/icona-tab-3-white.png') top left no-repeat;
                        }
                    }
                    &:hover {
                        background: linear-gradient(to bottom, $blue, $blue-dark);
                        border-top: 1px solid $blue-dark;
                        border-right: 1px solid $blue-dark;
                        border-left: 1px solid $blue-dark;
                        h4 {
                            .icon-famiglia-tab {
                                background: url($images-path + '/tab/icona-tab-1-white.png') top left no-repeat;
                            }
                            .icon-casa-tab {
                                background: url($images-path + '/tab/icona-tab-2-white.png') top left no-repeat;
                            }
                            .icon-personalizzabile-tab {
                                background: url($images-path + '/tab/icona-tab-3-white.png') top left no-repeat;
                            }
                        }
                    }
                }
                &:hover {
                    border-radius: 0;
                    background: #9999d2;
                    color: white;
                    h4 {
                        .icon-famiglia-tab {
                            background: url($images-path + '/tab/icona-tab-1-white.png') top left no-repeat;
                        }
                        .icon-casa-tab {
                            background: url($images-path + '/tab/icona-tab-2-white.png') top left no-repeat;
                        }
                        .icon-personalizzabile-tab {
                            background: url($images-path + '/tab/icona-tab-3-white.png') top left no-repeat;
                        }
                    }
                }
                .deleted {
                    text-decoration: line-through;
                    font-weight: $font-weight-medium;
                }
                h4 {
                    font-size: 22px;
                    line-height: 26px;
                    @include media-breakpoint-down(lg) {
                        font-size: 16px;
                        line-height: 20px;
                    }
                    @include media-breakpoint-down(xs) {
                        font-size: 12px;
                        line-height: 16px;
                    }
                    .icon-famiglia-tab, .icon-casa-tab, .icon-personalizzabile-tab {
                        margin-top: 5px;
                        width: 40px;
                        height: 36px;
                        float: left;
                        @include media-breakpoint-down(sm) {
                            float: none;
                            margin: 0 auto 10px auto;
                            display: block;
                        }

                    }
                    .icon-famiglia-tab {
                        background: url($images-path + '/tab/icona-tab-1-blu.png') top left no-repeat;
                    }
                    .icon-casa-tab {
                        background: url($images-path + '/tab/icona-tab-2-blu.png') top left no-repeat;
                    }
                    .icon-personalizzabile-tab {
                        background: url($images-path + '/tab/icona-tab-3-blu.png') top left no-repeat;
                    }
                }
                h5 {
                    font-size: 22px;
                    @include media-breakpoint-down(sm) {
                        font-size: 16px;
                        line-height: 20px;
                    }
                    @include media-breakpoint-down(xs) {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
                p {
                    width: 100%;
                    font-size: 14px;
                    font-weight: normal;
                    margin-bottom: 0;
                    &.label {
                        @include media-breakpoint-down(xl) {
                            display: none;
                        }
                    }
                }
                &#personalizzabile-tab {
                    h4 {
                        padding-top: 15px;
                        @include media-breakpoint-down(xs) {
                            padding-top: 0;
                        }
                    }
                    .icon-personalizzabile-tab {
                        margin-top: -9px;
                        @include media-breakpoint-down(xs) {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}

.tab-content {
    width: 100%;
}
