/* BLADE: source/_partials/landing/base/header.blade.php */
/* SCSS:  source/_assets/sass/partials/base/_header.scss */

.mobile-nav {
    background-color: white;
    z-index: 10;
    position: fixed;
    right: -100%;
    box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.4);
    ul {
        list-style: none;
        padding: 0;
        background-color: white;
        li {
            border-bottom: 0.0625rem solid $gray-light;
            padding: 0 0 0 0.625rem;
            width: 100%;
            height: 40px;
            a {
                display: block;
                height: 100%;
                width: 100%;
                font-family: $font-family-base;
                padding: 0 0 0 0.625rem;
                @include rem((
                        font-size: 14px,
                        line-height: 40px
                ));
                color: $blue;
                font-weight: 600;
                text-transform: uppercase;
                text-shadow: 0 1px 0 rgba(255, 255, 255, .25);
                position: relative;
                img {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 10px;
                }
            }
            &.active, &:hover {
                background-color: $blue;
                a {
                    color: white;
                    text-decoration: none;
                }
            }
            &.grey {
                background-color: $gray-bg;
                &.active, &:hover {
                    background-color: $gray-bg;
                }
            }
        }
    }
}

header {
    z-index: 10;
    background-color: transparent;
    top: 0;
    right: 0;
    width: 100%;
    height: 64px;
    @include media-breakpoint-down(md) {
        //height: 98px;
        &.header__small {
            height: 64px;
        }
    }
    .header {
        @extend .clearfix;
        & > div {
            @include rem((
                    padding-top: 16px,
                    padding-bottom: 16px,
                    height: 64px
            ));
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                @include rem((margin-left: 30px));
                @extend .clearfix;
                li {
                    float: left;
                    margin: 0;
                    padding: 0;
                    a {
                        position: relative;
                        display: block;
                        width: 100%;
                        height: 100%;
                        color: $blue;
                        text-transform: uppercase;
                        text-decoration: none;
                        font-family: $font-family-base;
                        font-weight: bold;
                        @include rem((
                                padding-left: 30px,
                                padding-right: 30px,
                                font-size: 14px,
                                line-height: 32px
                        ));
                        &:hover {
                            color: white;
                            text-decoration: none;
                            &:before {
                                position: absolute;
                                top: -16px;
                                left: 0;
                                width: 100%;
                                height: 64px;
                                content: " ";
                                background-color: #004994;
                                transform: skew(-20deg);
                                -ms-transform: skew(-20deg);
                                -o-transform: skew(-20deg);
                                -moz-transform: skew(-20deg);
                                -webkit-transform: skew(-20deg);
                            }
                        }
                    }
                    &.active {
                        a {
                            color: white;
                            text-decoration: none;
                            &:before {
                                position: absolute;
                                top: -16px;
                                left: 0;
                                width: 100%;
                                height: 64px;
                                content: " ";
                                background-color: $blue;
                                transform: skew(-20deg);
                                -ms-transform: skew(-20deg);
                                -o-transform: skew(-20deg);
                                -moz-transform: skew(-20deg);
                                -webkit-transform: skew(-20deg);
                            }
                        }
                    }
                }
            }
            ul.services {
                margin: 0;
                padding: 0;
                border: 0;
                position: absolute;
                top: 0;
                right: 15px;
                display: block;
                height: 64px;
                @include rem((padding: 0 20px));
                @extend .clearfix;
                li.services__elem {
                    list-style: none;
                    margin: 0;
                    border: 0;
                    padding: 0;
                    display: block;
                    float: left;
                    @include rem((padding-right: 15px));
                    img {
                        cursor: pointer;
                    }
                    a {
                        margin: 0;
                        padding: 0;
                        text-decoration: none;
                        &:hover:before {
                            display: none;
                        }
                        img {
                            border: 0;
                        }
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 64px;
                    content: "";
                    background-color: #f5f5f5;
                    transform: skew(-20deg);
                    -ms-transform: skew(-20deg);
                    -o-transform: skew(-20deg);
                    -moz-transform: skew(-20deg);
                    -webkit-transform: skew(-20deg);
                    z-index: -1;
                }

            }
            & > a > img {
                display: block;
                max-height: 44px;
                width: auto;
                @include rem ((margin-top: -5px,))
            }
        }
        a {
            &:hover {
                text-decoration: none;
            }
            &.open-mobile-nav {
                @include rem ((font-size:26px))
            }
        }

        .logo {
            position: relative;
            h4 {
                position: absolute;
                top: 50%;
                left: 65px;
                transform: translateY(-50%);
                margin: 0;
                @include media-breakpoint-only(lg) {
                    @include rem((font-size:20px));
                }
                @include media-breakpoint-only(sm) {
                    @include rem((font-size:24px));
                }
                @include media-breakpoint-only(xs) {
                    @include rem((font-size:13px, line-height:16px));
                }
            }
        }
    }
}