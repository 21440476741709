.imageGrid {

  &__text{
    background-color: $gray-bg;

    &__content{

      @include media-breakpoint-down (md){
        h3 { @include rem ((font-size: 20px,)); }
        @include rem ((padding: 20px 50px, margin-bottom:2px ));
        &--first{ @include rem ((padding-top: 30px, )); }
        &--last{ @include rem ((padding-bottom: 30px, )); }
      }

      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        @include rem ((padding: 0px 50px,));
      }
    }

    @include media-breakpoint-down (lg) {
      h3 { @include rem ((font-size: 20px,)); }
      p { @include rem ((font-size: 14px,)); }
    }

    @include media-breakpoint-up(lg) {
      min-height: 270px;
      position: relative;

      &::after{
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        content: "";
        z-index: 100;
      }
    }

    &--left{
      @include media-breakpoint-up(lg) {
        &::after {
          right: -16px;
          border-width: 16px 0 16px 16px;
          border-color: transparent transparent transparent $gray-bg;
        }
      }
    }

    &--right{
      @include media-breakpoint-up(lg) {
        &::after {
          left: -16px;
          border-width: 16px 16px 16px 0;
          border-color: transparent $gray-bg transparent transparent;
        }
      }
    }

    .numero1{
      &__container { display: block; position: relative;  @extend .clearfix; @include rem ((margin-bottom: 10px, )); }

      @include media-breakpoint-down(md) {
        &__brand {
          width:50px;
          @include rem ((margin-bottom: 10px, ));
          img{ width: 50px; height:auto;}
        }
        &__h3{ @include rem ((margin-bottom: 0px, )); }
      }

      @include media-breakpoint-up(lg) {
        &__brand { width:75px; }
        &__h3 { position: absolute; top: 50%; transform: translate(0, -50%); left: 95px; width:calc(100% - 95px); }
      }
    }

    .h3--brand{

      @include media-breakpoint-down(md) {
        @include rem ((padding-top: 60px, ));
        background-image: url($images-path + "/image-grid/numero1.png");
        background-repeat: no-repeat;
        background-size: 50px auto;
        display: inline-block;
      }

      @include media-breakpoint-up(lg) {
        min-height: 75px;
        @include rem ((padding-left: 90px, ));
        background-image: url($images-path + "/image-grid/numero1.png");
        background-repeat: no-repeat;
        background-size: 75px auto;
        display: inline-block;
      }

    }
  }

  &__image {

    display: none;
    @include media-breakpoint-up(lg) { display: block; }

    background-size: cover;
    width: 100%;
    height: 100%;
    min-height: 270px;

    &--agente{
      background-image: url($images-path + "/image-grid/agente.jpg");
    }
    &--brand{
      background-image: url($images-path + "/image-grid/brand.jpg");
    }
    &--postprotetto{
      background-image: url($images-path + "/image-grid/postprotetto.jpg");
    }

  }

  &__cta{
    padding: 20px;
    text-align: center;

    h3{ margin:30px 0px; }
  }

}