/* BLADE: source/_partials/forms/input-radio.blade.php */
/* SCSS:  source/_assets/sass/partials/base/forms/_input-radio.scss */

.form-check-inline {
    padding-left: 0;
}

.form-check {
    margin-top: 0;
    + .form-check {
        margin-top: 0;
    }
}

.radio{

    &__label{
        display: block;
        @include rem((margin-top: 10px, ));
        @include media-breakpoint-down (sm) { @include rem ((font-size: 14px, )); }
    }
    &__buttons{
        display: block;
        @include rem((margin-top: 5px, ));
        @include media-breakpoint-down (sm) { @include rem ((font-size: 14px, )); }
    }

    &__inline{
        position: relative; display: block; width: 100%; height: 45px; @include rem((margin-bottom: 15px, ));
        &__inner{
            position: absolute; top: 50%; left: 0px; transform: translate(0px, -50%);
            .radio__label{
                display: block; float: left; line-height: 28px; color: $orange; font-weight: $font-weight-medium; text-transform: uppercase;
                @include rem(( margin: 0px 20px 0px 0px, font-size: 16px));
                @include media-breakpoint-down (lg) { @include rem((font-size: 14px)); }
            }
            .radio__buttons{
                display: block; float: left;
                @include rem((margin: 0px, ));
                label{ @include rem((margin: 0px, )); }
            }
            @extend .clearfix;
        }
    }
    &__container{ }
}

.form-check-inline + .form-check-inline{
    @include media-breakpoint-down (lg) { @include rem((margin-left: 6px)); }
}

.radio__std {
    $radioHeight: 26px !default;
    .control-group {
        display: block;
        float: left;
    }
    .control-group:last-child {
        margin-bottom: 0;
    }
    label.radio {
        line-height: $radioHeight + 2px;
        min-height: $radioHeight + 2px;
        float: left;
        position: relative;
        cursor: pointer;
        //@include rem((margin-bottom: 15px));
    }
    label.radio [type="radio"] {
        display: block;
        opacity: 0;
        position: absolute;
        left: -9999px;
    }
    [type="radio"] + span.radio-labelContainer {
        @-moz-document url-prefix() {
            height: $radioHeight + 2px;
        }
        min-height: $radioHeight + 2px;
        display: table;
        padding-left: $radioHeight + 10px;
        @include media-breakpoint-down (lg) { padding-left: $radioHeight + 5px; }
        .radio-label {
            display: table-cell;
            vertical-align: middle;
            font-family: $font-family-base;
            @include rem((font-size: 16px));
            @include media-breakpoint-down (lg) { @include rem((font-size: 14px)); }
            color: $gray-light;
        }
        font-family: $font-family-base;
        font-weight: 400;
        @include rem((font-size: 16px));
        color: white;
        width: auto;
        float: left;
        margin: 0;
        @include media-breakpoint-down (xs) {
            font-size: 13px;
            height: auto;
        }
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            width: $radioHeight;
            height: $radioHeight;
            border: 2px solid $gray-form;
            background-color: transparent;
            border-radius: 50%;
        }
    }
    :disabled + span.radio-labelContainer {
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            width: $radioHeight;
            height: $radioHeight;
            background-color: transparent;
            border-radius: 50%;
            border: 2px solid $gray-form;
        }
        cursor: default;
        .radio-label {
            display: table-cell;
            color: $gray-form;
        }
    }
    :checked + span.radio-labelContainer {
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            width: $radioHeight;
            height: $radioHeight;
            border: 2px solid $gray-form;
            background-color: transparent;
            border-radius: 50%;
        }
        &:after {
            position: absolute;
            top: 7px;
            left: 7px;
            content: "";
            width: 12px;
            height: 12px;
            background-color: $orange;
            border-radius: 50%;
        }

        display: table;
        color: $orange;
        .radio-label {
            display: table-cell;
            color: $orange;
        }
        @include media-breakpoint-down (xs) {
            font-size: 13px;
        }
    }
    :focus + span.radio-labelContainer {
        border-color: $orange;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba($orange,.075), 0 0 8px rgba($orange, .6);
        box-shadow: inset 0 1px 1px rgba($orange,.075), 0 0 8px rgba($orange, .6);

        //outline: 5px solid;
        //outline: auto -webkit-focus-ring-color;
        //outline-color: $orange;

    }
}