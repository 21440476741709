/* BLADE: source/_partials/forms/textare.blade.php */
/* SCSS:  source/_assets/sass/partials/base/forms/_textarea.scss */

.textarea-box {
    textarea {
        @include rem ((font-size: 16px, padding: 12px 10px 0 10px));
        @include media-breakpoint-down (lg) {
            @include rem((font-size: 14px));
        }
        border: 1px solid $gray-form;
        &.form-control {
            height: auto;
        }
        &:focus {
            border: 1px solid $gray-form;
        }
    }
    label {
        display: none;
        //color: $gray-light;
        //@include rem ((font-size: 16px, line-height:45px));
        //@include media-breakpoint-down (lg) {
        //    @include rem((font-size: 14px));
        //}
        //position: absolute;
        //pointer-events: none;
        //left: 10px;
        //top: 0;
        //transition: 0.2s ease all;
        //-moz-transition: 0.2s ease all;
        //-webkit-transition: 0.2s ease all;
    }
}