// Libreria stili generica per i form

.form-group {
    border: none;
    color: $gray-dark;
}

.form-control {
    border: none;
    color: $gray-dark;
    height: 45px;

    &:focus {
        border-color: $orange;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($orange, 0.6);
    }
}
