/* BLADE: source/_partials/landing/ruota-mobile.blade.php */
/* Called: source/_assets/partials/_landing-page.scss - row: 44 */

.ruota-mobile {
    font-family: $font-family-base;
    @include rem((margin: 20px 0));
    &__title {
        h3 {
            font-weight: $font-weight-medium;
            color: $orange;
            margin: 0;
            padding: 0;
            text-align: center;
            text-transform: uppercase;
            @include rem((
                    font-size: 28px,
                    line-height: 32px,
                    margin-bottom: 15px
            ));
        }
        p {
            font-weight: $font-weight-medium;
            margin: 0;
            padding: 0;
            text-align: center;
            @include rem((
                    font-size: 16px,
                    line-height: 20px,
                    margin-bottom: 15px
            ));
        }
    }
    h4 {
        font-weight: $font-weight-medium;
        color: $blue;
        margin: 0;
        padding: 0;
        text-align: center;
        //text-transform: uppercase;
        @include rem((
                font-size: 24px,
                line-height: 28px,
                margin-bottom: 20px,
                padding-top: 20px
        ));
        border-top: 1px solid $gray-lighter;
    }
    .ruota-mobile__carousel {
        &__item {
            .item__box {
                position: relative;
                padding-bottom: 15px;
                display: table;
                &__img {
                    display: table-cell;
                    width: 40px;
                    height: 40px;
                    padding: 0;
                    vertical-align: top;
                }
                &__descri {
                    display: table-cell;
                    vertical-align: middle;
                    h5 {
                        border: 0;
                        font-weight: $font-weight-medium;
                        color: $orange;
                        padding: 0;
                        text-align: left;
                        text-transform: uppercase;
                        @include rem((
                                font-size: 16px,
                                line-height: 18px,
                                margin-bottom: 0
                        ));
                    }
                    p {
                        text-align: left;
                        /*font-family: $font-family-medium;*/
                        @include rem((
                                font-size: 16px,
                                line-height: 20px,
                                margin-bottom: 0
                        ));
                    }
                }
            }
        }
    }
    .slick-dots li {
        position: relative;
        display: inline-block;
        height: 1rem;
        width: 1rem;
        @include rem((margin: 0 5px));
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            width: 1rem;
            height: 1rem;
            outline: none;
            line-height: 0;
            font-size: 0;
            color: transparent;
            @include rem((padding: 5px));
            cursor: pointer;
            &:before {
                width: 1rem;
                height: 1rem;
                font-size: 0.75rem;
                line-height: 1rem;
                color: $gray-light;;

            }
        }
        &.slick-active {
            button:before {
                color: $orange;
            }
        }
    }
}

