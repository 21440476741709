%visuallyhidden {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute;
}

.visually-hidden {
    @extend %visuallyhidden;
}

/*
  Vertical Align
*/
@mixin ghostVerticalAlign(){
    &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        height: 100%; width: .1px;
    }
}

/*
  Vertical align without height - Parent MUST be set with "position:relative"
*/
@mixin vertical-align {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

@mixin clrfix() {
    @include clearfix();
    content: " ";
    display: block;
    clear: both;
    overflow: hidden;
    visibility: hidden;
}

@mixin font-smoothing($value: antialiased) {
    @if $value == antialiased {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}
@mixin box_fixed_height ($value: 208px) {
    .axa-box-body {
        height: $value;
    }
}

@mixin centerItems( $direction:row,  $justifyContent:left, $alignItems:center ) {
    // Compatibility from IE11 and all mobile device
    display: block;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    //@include flex-direction($direction);
    -webkit-flex-direction: $direction;
    flex-direction: $direction;
    //@include justify-content($justifyContent);
    -webkit-justify-content: $justifyContent;
    justify-content: $justifyContent;
    //@include align-items($alignItems);
    -webkit-align-items: $alignItems;
    align-items: $alignItems;
}