/* BLADE: source/_partials/forms/select.blade.php */
/* SCSS:  source/_assets/sass/partials/base/forms/_select.scss */

.form-group {
    .select-type {
        border: 1px solid $gray-form;
        position: relative;
        @include rem ((padding:8px));
        label {
            position: absolute;
            top: 5px;
            font-weight: $font-weight-medium;
            text-transform: uppercase;
            @include rem ((font-size: 12px, line-height:12px));
            color: $orange;
        }
        select {
            background: transparent;
            color: $gray;
            @include rem ((line-height:45px));
            &.select {
                font-family: $font-family-base;
                @include rem ((font-size: 16px, line-height: 45px));
                width: 100%;
                height: 36px;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                text-overflow: '';
                border: none;
                &:focus {
                    outline: none;
                }
            }
        }

        &:after {
            font-family: FontAwesome;
            font-size: 14px;
            line-height: 45px;
            color: $orange;
            top: 0;
            right: 25px;
            content: "\f078";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            z-index: 9;
            @include media-breakpoint-down (xs) {
                right: 10%;
            }
        }
    }
}

.info-select {
    .info {
        @include media-breakpoint-down (md) {
            @include rem ((font-size:16px));
            top: 0;
            right: -5px;
        }
    }
}

select::-ms-expand {
    display: none;
}

select::-ms-value {
    background: none;
    color: #666;
}