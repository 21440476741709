/* BLADE: source/_partials/sidebar.blade.php */
/* SCSS:  source/_assets/sass/partials/_sidebar.scss */

.sidebar {
    position: fixed;
    z-index: 1000;
    display: block;
    right: 0;
    top: 0;
    height: 100%;
    width: $sidebar-width;
    background-color: white;
    @include media-breakpoint-down(md) {
        top: auto;
        bottom: 0;
        width: 100%;
        height: auto;
        max-height: calc(100% - #{$sidebar-mobile-bar-height});
        padding: 0;
        border-left: none;
    }
    &__desktop {
        &__protection {
            background-color: $gray-bg;
            @include rem((padding: 30px));

            .title {
                color: $gray;
                text-align: center;
                font-weight: $font-weight-medium;
                @include rem((font-size: 18px, margin-bottom: 20px));
            }

            .gauge-container {
                display: block;
                width: 180px;
                height: 80px;
                margin: auto;
                overflow: hidden;

                .GaugeMeter {
                    position: relative;
                    span {
                        width: 100%;
                        position: absolute;
                        text-align: center;
                        color: $gray;
                        font-weight: $font-weight-medium;
                        @include rem ((line-height: 140px !important, font-size: 24px !important,))
                    }
                    u {
                        font-size: 60%;
                        text-decoration: none;
                    }
                }
            }
        }
        &__quotation {
            background-color: white;
            @include rem((padding: 20px 20px 0 20px));
            .title {
                color: $blue-dark;
                text-align: center;
                @include rem((font-size: 20px));
            }
            .promo {
                position: relative;
                display: block;
                height: 35px;
                width: 100%;
                @include rem((margin: 20px 0px,));
                &__inner {
                    display: block;
                    width: 100%;
                    height: 35px;
                    position: absolute;
                    left: -20px;
                    color: white;
                    background-color: $green;
                    font-weight: $font-weight-medium;
                    text-transform: uppercase;
                    @include rem((font-size: 16px, line-height: 35px, padding: 0px 10px));

                    strong {
                        font-weight: $font-weight-bold;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        top: 0px;
                        right: -17px;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0px 0 35px 17px;
                        border-color: transparent transparent transparent $green;
                    }
                }
            }
        }
        &__price {
            @include rem((padding: 0 20px 15px 20px));
            background: transparent url($images-path + '/carrello/bg-btn-carrello-shadow.png') no-repeat;
            background-position: left 16px;
            .container-btn-carrello {
                position: absolute;
                top: 320px;
                left: -48px;
                width: 80px;
                background: transparent url($images-path + '/carrello/bg-btn-carrello.png') top left no-repeat;
                cursor: pointer;
                &:hover {
                    background: transparent url($images-path + '/carrello/bg-btn-carrello-hover.png') top left no-repeat;
                }
                a {
                    &.btn-carrello {
                        position: relative;
                        width: 66px;
                        height: 80px;
                        display: block;
                        text-align: center;
                        border-bottom-left-radius: 80px;
                        border-top-left-radius: 80px;

                        &:hover {
                            text-decoration: none;
                        }

                        &:before {
                            font-family: "FontAwesome", sans-serif;
                            color: white;
                            content: "\f07a";
                            margin-right: 10px;
                            font-size: 30px;
                            line-height: 80px;
                        }
                        span {
                            position: absolute;
                            top: 35%;
                            transform: translateY(-50%);
                            left: 50%;
                            z-index: 1;
                            &.animated {
                                top: 25%;
                            }
                            &.badge-light {
                                color: white;
                                background-color: $orange;
                            }
                            &.badge {
                                font-size: 90%;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }
            .container-btn-carrello-two {
                position: absolute;
                top: 320px;
                left: -48px;
                width: 80px;
                background: transparent url($images-path + '/carrello/bg-btn-carrello-open.png') top left no-repeat;
                cursor: pointer;
                a {
                    &.btn-carrello {
                        position: relative;
                        width: 66px;
                        height: 80px;
                        display: block;
                        text-align: center;
                        border-bottom-left-radius: 80px;
                        border-top-left-radius: 80px;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
                &--open {
                    position: absolute;
                    top: 333px;
                    left: -546px;
                    width: 80px;
                    background: transparent url($images-path + '/carrello/bg-btn-carrello-open.png') top left no-repeat;
                    &:hover {
                        background: transparent url($images-path + '/carrello/bg-btn-carrello-open-hover.png') top left no-repeat;
                    }
                }
            }
            .price {
                text-align: left;
                @include rem((margin-bottom: 0px,));
                &--bar {
                    color: $gray;
                    @include rem((font-size: 25px, line-height: 25px, padding: 0 15px));

                    span {
                        position: relative;
                        @include rem((padding: 0px 8px,));
                        &:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 50%;
                            right: 0;
                            border-top: 2px solid $blue-dark;
                            -webkit-transform: rotate(-5deg);
                            -moz-transform: rotate(-5deg);
                            -ms-transform: rotate(-5deg);
                            -o-transform: rotate(-5deg);
                            transform: rotate(-5deg);
                        }
                    }
                }
                &--big {
                    position: relative;
                    color: $blue-dark;
                    font-weight: $font-weight-bold;
                    @include rem((font-size: 42px, line-height: 55px, padding: 0 15px));
                    span {
                        float: left;
                        margin-right: 10px;
                        &.valuta {
                            margin-right: 0;
                            font-size: 19px;
                            line-height: 18px;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
                &--txt {
                    color: $blue-dark;
                    font-weight: $font-weight-medium;
                    @include rem((font-size: 18px, line-height: 18px, padding: 0 15px));
                }
            }
        }
        &__actions {
            @include rem((padding: 0 35px,));
            background-color: white;
            .download {
                color: $orange;
                text-align: center;
                @include rem((font-size: 13px,));
                a {
                    color: $orange;
                    font-weight: $font-weight-light;
                    &.not-active {
                        pointer-events: none;
                        cursor: not-allowed;
                        opacity: 0.5;
                    }
                }
            }
        }
    }
    &__mobile {
        &__garancies {
            background-color: white;
            display: none;
            &--active {
                display: block;
            }
            &__close {
                height: 10px;
                width: 100%;
                background-color: $blue-dark;
                position: relative;
                i {
                    background-color: $blue-dark;
                    position: absolute;
                    top: -10px;
                    right: 10px;
                    color: white;
                    padding: 8px 10px;
                    font-size: 14px;
                    border-radius: 50%;
                    line-height: 14px;
                }
            }
            .garancy__list {
                overflow: scroll;
                max-height: 166px;
                @include rem(padding, 15px 10px 10px);
                &__elem {
                    overflow-y: hidden;
                    background-color: #f8f8f8;
                    margin-bottom: 10px;
                    @include rem((
                            padding: 10px 12px,
                    ));
                    &__name {
                        color: $blue-dark;
                        width: auto;
                        float: left;
                        font-weight: $font-weight-regular;
                        @include rem((
                                font-size: 18px,
                                line-height: 28px
                        ));
                    }
                    &__price {
                        font-size: 20px;
                        color: $blue-dark;
                        width: auto;
                        float: right;
                        font-weight: $font-weight-medium;
                        text-align: center;
                        span {
                            color: $orange;
                            cursor: default;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
        &__promo {
            position: relative;
            background-color: $green;
            color: white;
            text-align: center;
            text-transform: uppercase;
            @include rem((line-height: 30px,));
            strong {
                font-weight: $font-weight-bold;
            }
            &:after {
                content: "";
                position: absolute;
                bottom: -10px;
                left: 50%;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 8px 0 8px;
                border-color: $green transparent transparent transparent;
            }
        }

        &__quotation {
            background-color: $blue;
            @include rem((padding: 10px 15px,));
            p {
                font-weight: normal;
                color: white;
                @include rem((font-size: 14px, margin-bottom: 0px,));

                .price--bar {
                    text-decoration: line-through;
                    @include rem((margin-right: 10px,));
                }
                .price--big {
                    @include rem((font-size: 22px,));
                    font-weight: $font-weight-bold;
                }

                @include media-breakpoint-only (md) {
                    @include rem ((font-size:18px));
                    .price--big {
                        font-weight: $font-weight-bold;
                        @include rem((font-size: 26px,));
                    }
                }
            }
            .cart-container {
                display: block;
                width: 100%;
                height: 100%;
                position: relative;
                .container-btn-carrello--mobile {
                    position: absolute;
                    background-color: $blue-dark;
                    top: 50%;
                    right: -14px;
                    transform: translate(0, -50%);
                    width: 74px;
                    height: 74px;
                    border-left: 1px solid white;
                    a {
                        &.btn-carrello {
                            position: relative;
                            width: auto;
                            height: 100%;
                            display: block;
                            text-align: center;

                            &:hover {
                                text-decoration: none;
                            }

                            &:before {
                                font-family: "FontAwesome", sans-serif;
                                color: white;
                                content: "\f07a";
                                margin-right: 10px;
                                font-size: 30px;
                                line-height: 80px;
                            }
                            span {
                                position: absolute;
                                top: 40%;
                                transform: translateY(-50%);
                                left: 50%;
                                z-index: 1;
                                &.badge-light {
                                    color: white;
                                    background-color: $orange;
                                }
                                &.badge {
                                    font-size: 90%;
                                    border-radius: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__actions {
            display: block;
            width: 100%;
            background-color: white;
            .item {
                display: inline-block;
                width: 50%;
                float: left;
                @include rem((padding: 2px 1px,));
                @include media-breakpoint-down (sm) {
                    .btn {
                        padding: 0;
                        line-height: rem(15px);
                        font-size: rem(15px);
                    }
                }
            }
            @extend .clearfix;
        }

    }
}
