/* BLADE: source/_partials/carrello.blade.php */
/* SCSS:  source/_assets/sass/partials/_carrello.scss */

#carrelloSidebar {
    z-index: 999;
    &.carrello {
        position: fixed;
        top: 0;
        bottom: 0;
        width: 500px;
        padding: 20px 40px;
        //margin-right: 315px;
        background-color: white;
        box-shadow: inset -7px 0px 8px -7px;
        border-left: 6px solid $blue-dark;
        right: -179px;
        //right: -179px;
        @include media-breakpoint-down(md) {
            top: auto;
            bottom: 0;
            width: 100%;
            height: auto;
            max-height: calc(100% - #{$sidebar-mobile-bar-height});
            padding: 0;
        }
        h3 {
            color: $blue-dark;
            margin-bottom: 20px;
        }
        .carrello-box {
            height: 80%;
            overflow-y: auto;
        }
        .carrello-close {
            position: relative;
            .carrello-close-btn {
                color: $orange !important;
            }
        }
        .carrello_item {
            background-color: #f8f8f8;
            padding: 10px 12px;
            p {
                margin: 0;
                &.title {
                    color: $blue-dark;
                    font-weight: $font-weight-regular;
                }
                &.deleted {
                    text-decoration: line-through;
                    font-weight: $font-weight-medium;
                    font-size: 12px;
                    text-align: right;
                }
                &.price {
                    text-align: right;
                    color: $blue-dark;
                    font-weight: $font-weight-medium;
                }
                span {
                    color: $orange;
                    cursor: default;
                    float: right;
                    margin-left: 10px;
                }
            }
        }
        .riepilogo__inner {
            margin-top: 20px;
            padding: 0;
            p {
                margin-bottom: 10px;
            }
            a {
                color: $orange;
            }
        }
    }
    &.carrello_open {
        right: $sidebar-width;
    }
}

.page-backdrop {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background-color: #000000;
    opacity: 0.5;
}