/* BLADE: source/_partials/premio/convenzionali.blade.php */
/* SCSS:  source/_assets/sass/partials/modules/premio/_convenzionali.scss */

.accordion-convenzionali {
    p {
        strong {
            font-weight: $font-weight-medium;
        }
        @include media-breakpoint-down(sm) {
            font-size: $font-size-sm;
        }
    }
    &__row {
        border-bottom: 1px solid $gray-form;
        @include rem (( margin-top:20px));
        min-height:30px;
        font-family: $font-family-base;
        font-size: $font-size-base;

        .item-text {
            float: left;
            color: $orange;
        }
        .item-value {
            float: right;
            font-weight: $font-weight-medium;
        }

        @include media-breakpoint-down(sm) {
            font-size: $font-size-sm;
            .item-text {  float: none;  }
            .item-value {
                float: none;
                @include rem(( padding: 6px 0 ));
            }

        }

    }
}