/* BLADE: source/_partials/premio/card.blade.php */
/* SCSS:  source/_assets/sass/partials/modules/premio/_card.scss */

$shadow-hover: 0 0 30px 0 rgba($orange, 0.50);

$karteW: 185px;
$karteH: 160px;
$karteH_mob: 60px;

.karte {
    display: block;
    width: 100%;
    margin-top: 1rem;

    &__element {
        transition: all 0.3s;
        cursor: pointer;
        float: left;
        position: relative;
        width: $karteW;
        height: $karteH;
        background: $gray-bg; /* For browsers that do not support gradients */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14), 0 2px 3px rgba(0, 0, 0, 0.26);
        @include rem((
                margin-right: 22px,
                margin-bottom: 20px,
                padding: 15px 10px,
        ));
        &.karte__element--hidden {
            display: none;
        }
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        &:hover {
            box-shadow: 0 2px 4px rgba(255, 128, 0, 0.60), 0 8px 12px rgba(0, 0, 0, 0.26);
        }
        &.active {
            background: $blue; /* For browsers that do not support gradients */
            background: linear-gradient(to bottom, $blue, $blue-dark);
            * {
                color: white !important;
            }
            .karte__cta {
                &__link {
                    border-top: 1px solid #4a619c;
                    &:hover {
                        background-color: rgba(white, 0.35);
                    }
                }
            }
            .karte__circle {
                position: relative;
                &:after {
                    content: '';
                    width: 33px;
                    height: 21px;
                    position: absolute;
                    top: -3px;
                    left: 5px;
                    border: 8px solid $orange;
                    border-top: none;
                    border-right: none;
                    background: transparent;
                    opacity: 1;
                    transform: rotate(-45deg);
                }
            }
        }
        &.required {
            cursor: default;
            background: $blue; /* For browsers that do not support gradients */
            background: linear-gradient(to bottom, $blue, $blue-dark);
            * {
                color: white !important;
            }
            .karte__title {
                font-weight: $font-weight-medium;
                font-size: 16px;
            }
            .karte__circle {
                position: relative;
                &:after {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    content: "\f023";
                    font-family: FontAwesome, sans-serif;
                    font-size: 26px;
                    font-weight: normal;
                    color: $orange;
                    transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    -o-transform: translate(-50%, -50%);
                    -moz-transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                }
            }
            .karte__cta {
                &__link {
                    border-top: 1px solid #4a619c;
                    &:hover {
                        background-color: rgba(white, 0.35);
                    }
                }
            }
            &:hover {
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14), 0 2px 3px rgba(0, 0, 0, 0.26);
            }
        }
        &.disabled {
            cursor: no-drop;
            &:hover {
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14), 0 2px 3px rgba(0, 0, 0, 0.26);
            }
            .karte__header {
                opacity: 0.3
            }
            .karte__body {
                opacity: 0.2
            }
            .karte__cta {
                opacity: 0.3
            }
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 2px;
                left: 2px;
                width: calc(100% - 4px);
                height: calc(100% - 4px);
                background-color: rgba(black, 0.3);
            }
        }
        &.error {
            box-shadow: 0 2px 4px rgba(255, 21, 16, 0.60), 0 8px 12px rgba(0, 0, 0, 0.26);
            border: 1px solid $red;
            &.active {
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14), 0 2px 3px rgba(0, 0, 0, 0.26);
                border: none;
            }
        }


        @include media-breakpoint-down(sm) {
            width: 100%;
            height: $karteH_mob;
        }
    }
    &__header {
        position: relative;
        height: 35px;
        @include rem((margin-bottom: 8px));
        .karte__title {
            float: left;
            position: absolute;
            left: 0;
            top: 0;
            width: calc(100% - 35px - 6px);
            font-size: $font-size-base;
            line-height: $font-size-base + 0.15rem;
            font-weight: $font-weight-medium;
            color: $blue;
        }
        .karte__circle {
            float: left;
            margin-left: calc(100% - 35px);
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: white;
            border: 1px solid #d2d2d2;
        }
        @include media-breakpoint-down(sm) {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: $karteH_mob;
            margin-bottom: 0;
            .karte__circle {
                position: absolute;
                margin-left: 0;
                float: none;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
            }
            .karte__title {
                top: auto;
                bottom: 50%;
                left: 61px;
                float: none;
                font-size: $font-size-sm;
                line-height: $font-size-sm;
                width: calc(100% - 70px - 25px - 35px);
            }
        }
    ;
        @include media-breakpoint-down(xs) {
            .karte__title {
                left: 55px;
                font-size: $font-size-xs;
                line-height: $font-size-xs;
            }
        }

    }
    &__body {
        position: relative;
        height: 70px;
        width: 100%;
        line-height: 0;
        .karte__inPromo {
            display: inline-block;
            background-color: $green;
            position: relative;
            color: white;
            font-weight: $font-weight-regular;
            font-size: $font-size-xxs;
            @include rem((
                    padding: 0 4px,
                    line-height: 14px
            ));
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: -10px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0 14px 10px;
                border-color: transparent transparent transparent $green;
            }
        }
        .karte__priceList {
            position: absolute;
            display: inline-block;
            left: 0;
            top: 20px;
            color: $gray;
            font-weight: $font-weight-light;
            font-size: $font-size-base;
            text-decoration: line-through;
            line-height: $font-size-base;
        }
        .karte__price {
            position: absolute;
            display: inline-block;
            left: 0;
            top: 36px;
            color: $blue;
            font-weight: $font-weight-bold;
            font-size: $font-size-lg;
            line-height: $font-size-lg;
        }
        @include media-breakpoint-down(sm) {
            height: $karteH_mob;
            position: absolute;
            left: 0;
            top: 0;
            .karte__inPromo {
                position: absolute;
                top: 4px;
                right: 11px;
            }

            .karte__priceList {
                left: 55px;
                top: calc(50% + 8px);
                font-weight: $font-weight-light;
                font-size: $font-size-sm;
                line-height: $font-size-sm;
                text-decoration: line-through;
            }
            .karte__price {
                left: 126px;
                top: calc(50% + 7px);
                font-weight: $font-weight-bold;
                font-size: $font-size-base;
                line-height: $font-size-base;
            }

        }
    }
    &__cta {
        position: absolute;
        left: 0;
        bottom: 2px;
        text-align: center;
        width: 100%;
        height: 32px;
        a {
            color: $gray-dark;
            text-decoration: none;
            font-size: $font-size-sm;
            font-weight: $font-weight-medium;
            text-transform: uppercase;
            transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
        }
        &__link {
            float: left;
            width: 100%;
            border-top: 1px solid #d4d4d4;
            @include rem((
                    line-height: 35px
            ));
            &:hover {
                background-color: rgba($orange, 0.20);
                color: $orange;
            }
        }
        @include media-breakpoint-down(sm) {
            height: $karteH_mob - 5px;
            position: absolute;
            right: 1px;
            top: 2px;
            left: auto;
            bottom: auto;
            width: 70px;
            a {
                color: $gray-dark;
                text-decoration: none;
                font-size: $font-size-sm;
                font-weight: $font-weight-medium;
                text-transform: uppercase;
                transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
            }
            &__link {
                float: left;
                width: 100%;
                border-top: 0 solid transparent !important;
                border-left: 1px solid #d4d4d4;
                @include rem((
                        line-height: $karteH_mob - 5px
                ));
            }
        }
    }
}