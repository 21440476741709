/* BLADE: source/thankyou.blade.php */
/* SCSS:  source/_assets/sass/partials/_thankyou.scss */
/* Called: source/_assets/partials/_styles.scss */

.thankyou {

  &__top {
    background-color: #fafafa;
    @include media-breakpoint-up (lg) {
      @include rem ((padding-left: 75px,));
    }

    @include media-breakpoint-up (lg) {
      .container-fluid {
        height: 290px;
        background: url($images-path + "/thankyou/thankyou-top.jpg");
        background-position: center right;
        background-repeat: no-repeat;
      }
    }
    .text {
      background-color: #fafafa;
      position: relative;
      display: inline-block;
      height: 290px;
      @include media-breakpoint-down (md) {
        width: 100%;
        text-align: center;
      }
      @include media-breakpoint-up (lg) {
        width: 50%;
        &::after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0px 0 290px 120px;
          border-color: transparent transparent transparent #fafafa;
          position: absolute;
          top: 0px;
          right: -120px;
        }
      }
      h1 {
        font-weight: $font-weight-medium; color: $blue;
        @include rem ((margin: 60px 0px 0px, font-size: 48px,));
        @include media-breakpoint-down (sm) { @include rem (( margin-top: 50px, font-size: 35px,)); }
      }
      h3 {
        font-weight: $font-weight-light; color: $blue;
        @include rem ((margin-bottom: 35px, font-size: 30px, ));
        @include media-breakpoint-down (sm) { @include rem ((margin-bottom: 25px, font-size: 20px,)); }
        strong { font-weight: $font-weight-medium; }
      }
      p {
        font-weight: $font-weight-light;
        @include rem ((font-size: 18px, ));
        @include media-breakpoint-down (sm) { @include rem ((font-size: 15px,)); }
        strong { font-weight: $font-weight-medium; }
      }
    }

    .image {
      @include media-breakpoint-down (md) {
        display: block;
        .container-fluid {
          height: 290px;
          background: url($images-path + "/thankyou/thankyou-top.jpg");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
      @include media-breakpoint-up (lg) {
        display: none;
      }
    }

  }

  &__content {
    background-color: #f7f7f7;
    padding-bottom: 20px;

    &__text {
      text-align: center;
      @include rem ((padding: 50px 0px,));
      h2 {
        font-weight: $font-weight-medium; color: $blue;
        @include rem ((margin: 0px 0px 20px, font-size: 36px,));
        @include media-breakpoint-down (sm) { @include rem ((font-size: 26px,)); }
      }
      p {
        font-weight: $font-weight-light; color: $blue;
        @include rem ((margin: 0px, font-size: 18px,));
        @include media-breakpoint-down (sm) { @include rem ((font-size: 15px ,)); }
        strong { font-weight: $font-weight-medium; }
      }
    }

    &__boxes {
      @include rem ((padding: 0px 40px,));
      @include media-breakpoint-down (md) { @include rem ((padding: 0px ,)); }

      .box {
        text-align: center;
        background-color: white;

        &--top {
          display: block; width: 100%;
          @include rem ((padding: 30px 50px,));
          @include media-breakpoint-down (sm) {
            @include rem ((padding: 30px 20px,));
            img { width:90px; height: auto; }
          }
          @include media-breakpoint-up (md) { height: 450px; }
        }
        &--bottom {
          display: block; width: 100%;
          @include rem ((padding: 20px 50px,));
          @include media-breakpoint-down (sm) {
            @include rem ((padding: 20px 35px,));
          }
          @include media-breakpoint-up (md) { height: 120px; }
        }

        h4 {
          margin: 20px 0px 40px; font-size: 24px; font-weight: $font-weight-light; color: $blue;
          strong { font-weight: $font-weight-medium; }
        }

        .text-blue {
          font-weight: $font-weight-medium; @include rem ((line-height: 22px ,));
          @include media-breakpoint-down (sm) { @include rem ((font-size: 14px ,)); }
          a { color: $orange; }
        }
        .text-light {
          font-weight: $font-weight-light; @include rem ((line-height: 20px ,));
          @include media-breakpoint-down (sm) { @include rem ((font-size: 13px ,)); }
        }
      }

      @include media-breakpoint-down (md) {
        .col-12:first-child .box { @include rem ((margin-bottom: 30px,)); }
      }
    }



    &__buttons {
      text-align: right;
      @include media-breakpoint-down (md) { text-align: center; }
      @include rem ((margin: 20px 0px 0px 0px, padding: 0px 40px,));
      p { @include rem ((margin: 0px,)); }
    }

  }

}