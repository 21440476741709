/* BLADE: source/_partials/premio/base/main.blade.php */
/* SCSS:  source/_assets/sass/partials/base/_main.scss */

$widthAside: 315px;
$headerHeight: 80px;

.wrapper {
    &__inner {
        &--bck {
            background: white url($images-path + '/background/sfondo.jpg') 0 100% no-repeat fixed;
            background-size: cover;
            .mainSector {
                margin-top: 20px;
                &:first-child {
                    @include rem ((
                            padding-top:20px,
                    ))
                }
            }
        }
        display: block;
        width: calc(100% - #{$widthAside});
        @include media-breakpoint-down(md) {
            width: 100%;
        }
        .sector {
            &.container-fluid {
                padding: 0
            }
            width: 100%;
            &__title {
                font-family: $font-family-base;
                font-weight: $font-weight-medium;
                @include media-breakpoint-down(sm) {
                    font-size: $font-size-base;
                    line-height: $font-size-base + 0.25rem;
                }
                @include media-breakpoint-down(sm) {
                    font-size: $font-size-base;
                    line-height: $font-size-base + 0.25rem;
                }
                @include media-breakpoint-down(xs) {
                    font-size: $font-size-sm;
                    line-height: $font-size-sm + 0.25rem;
                }
                padding: 6px 0;
                border-bottom: 1px solid #e6e6e6;
                margin: 0;
                display: block;
                position: relative;
                span {
                    float: left;
                    display: inline-block;
                    &.icon {
                        width: 35px;
                        height: 35px;
                        @include media-breakpoint-down(sm) {
                            width: 30px;
                            height: 30px;
                        }
                        @include media-breakpoint-down(xs) {
                            width: 40px;
                            height: 40px;
                        }
                        &--Casa {
                            background: url($images-path + '/sezioni/casa.svg') center left no-repeat;
                        }
                        &--Fuoco {
                            background: url($images-path + '/sezioni/fuoco.svg') center left no-repeat;
                        }
                        &--Famiglia {
                            background: url($images-path + '/sezioni/famiglia.svg') center left no-repeat;
                        }
                        &--Furto {
                            background: url($images-path + '/sezioni/furto.svg') center left no-repeat;
                        }
                        &--Quadro {
                            background: url($images-path + '/sezioni/quadro.svg') center left no-repeat;
                        }
                        &--Martello {
                            background: url($images-path + '/sezioni/martello.svg') center left no-repeat;
                        }
                        &--Computer {
                            background: url($images-path + '/sezioni/computer.svg') center left no-repeat;
                        }
                        &--Chiocciola {
                            background: url($images-path + '/sezioni/chiocciola.svg') center left no-repeat;
                        }
                        &--Zampa {
                            background: url($images-path + '/sezioni/zampa.svg') center left no-repeat;
                        }
                    }
                    &.text {
                        position: absolute;
                        left: 40px;
                        bottom: 6px;
                        @include media-breakpoint-down(lg) {
                            font-size: 20px;
                        }
                        @include media-breakpoint-down(sm) {
                            left: 40px;
                        }
                        @include media-breakpoint-down(xs) {
                            left: 50px;
                        }
                    }
                }
            }
            @import "../premio/card";
            @import "../premio/sconto";
        }
    }
}

.premio__error {
    width: 100%;
    @include rem((padding: 10px 0));
    font-weight: $font-weight-medium;
    text-align: center;
    background-color: #ffd9d9;
    color: $red;
    z-index: 999;
    span {
        text-align: left;
        position: relative;
        @include rem((
                padding-left: 45px,
        ));
        display: inline-block;
        width: 64%;
        @include rem((
                font-size: 18px,
                line-height:20px
        ));
        @include media-breakpoint-down(md) {
            display: inline-block;
            width: 90%;
            @include rem((
                    font-size: 16px,
                    line-height:18px
            ));
        }
        @include media-breakpoint-down(xs) {
            @include rem((
                    font-size: 14px,
                    line-height:16px
            ));
        }
        &:before {
            content: '';
            @include vertical-align();
            left: 0;
            display: block;
            height: 35px;
            width: 35px;
            background: url($images-path + '/sezioni/alert.svg') bottom left no-repeat;
        }
    }
    &.stuck {
        position: fixed;
        left: 0;
        top: 0;
        @include media-breakpoint-up(lg) {
            width: calc(100% - #{$sidebar-width});
        }
    }
}

.modal-header {
    padding: 15px 15px 8px 15px;
}

.modal-body {
    padding: 8px 15px 15px 15px;
}