/* /source/_assets/sass/partials/modules/errors/_error.scss */
/* /source/error.blade.php */

.pageError {
    @include rem ((margin-top:2px));
    border-top: 1px solid $gray-bg;
    @include media-breakpoint-down(sm) {
        .head__image {
            width: 120px;
            height: 120px;
        }

        h1 {
            @include rem((
                    margin: 20px 0,
                    font-size: 24px,
                    line-height: 28px
            ));
        }
        h2 {
            @include rem((
                    margin: 20px 0,
                    font-size: 16px,
                    line-height: 20px
            ));
        }
        ul {
            @include rem((
                    margin: 0 0 20px 0,
                    font-size: 14px,
                    line-height: 18px
            ));
        }
    }
    h1 {
        color: $blue;
        text-align: center;
        padding: 0;
        @include rem((
                margin: 0 0 40px 0,
                font-size: 32px,
                line-height: 36px
        ));
        font-weight: $font-weight-medium;
    }
    h2 {
        color: $blue;
        text-align: center;
        padding: 0;
        @include rem((
                margin: 0 0 40px 0,
                font-size: 24px,
                line-height: 28px
        ));
        font-weight: $font-weight-light;
        strong {
            font-weight: $font-weight-medium;
        }
    }
    p {
        @include rem((
                font-size: 16px,
                line-height: 20px
        ));
        strong {
            font-weight: $font-weight-medium;
        }

    }
    ul {
        @include rem((
                margin: 0 0 40px 0,
                font-size: 18px,
                line-height: 22px
        ));
        font-weight: $font-weight-light;
        li {
            list-style: circle;
            @include rem((margin-bottom:15px));
        }
    }
    img {
        max-width: 150px;
        &.small-img {
            max-width: 100px;
        }
    }
    p {
        a {
            color: $gray;
            text-decoration: underline;
        }
    }
}