/* BLADE: source/_partials/modals/rangeSlider.blade.php */
/* SCSS:  source/_assets/sass/partials/modules/premio/_range-slider.scss */

.modal__incendio {
    .range {
        @extend .clearfix;
        &__title {
            font-family: $font-family-base;
            @include rem((
                    font-size: 14px,
                    line-height:35px,
            ));
            text-align: right;
            @include media-breakpoint-down (xs) {
                text-align: center;
            }
        }
        &__number {
            width: 60%;
            @extend .range__title;
            font-weight: $font-weight-medium;
            text-align: center;
            border: 1px solid $gray-form;
            border-radius: 4px;
            @include media-breakpoint-down (xs) {
                @include rem ((margin:0 auto));
            }
        }
        &__input {
            width: calc(100% - 30px);
            @include rem((margin: 0 auto 40px auto));
            &:before {
                width: 22px;
                font-family: FontAwesome;
                content: "\f068";
                display: inline-block;
                color: $orange;
                @include rem ((font-size:18px))
            }
            &:after {
                text-align: right;
                width: 22px;
                font-family: FontAwesome;
                content: "\f067";
                display: inline-block;
                color: $orange;
                @include rem ((font-size:18px))
            }

            label {
                display: none;
            }
            .slider {
                &.slider-horizontal {
                    width: 90%;
                    height: 20px;
                    position: relative;
                    @include media-breakpoint-down (sm) {
                        width: 88%;
                    }
                    @include media-breakpoint-down (xs) {
                        width: 79%;
                    }
                    .slider-track {
                        @include vertical-align();
                        height: 10px;
                        margin-top: 0;
                    }
                }
            }
            .slider-tick-label-container {
                font-family: $font-family-base;
                font-weight: $font-weight-medium;
                @include rem((
                        font-size: 16px,
                        line-height:20px
                ));
                .slider-tick-label {
                    padding-top: 8px;
                }
                .slider-tick-label:first-child {
                    float: left;
                }
                .slider-tick-label:last-child {
                    float: right;
                }
            }
            .slider-track {
                border-radius: 5px;
                .slider-track-low, .slider-track-high {
                    border-radius: 5px
                }
                .slider-selection {
                    border-radius: 5px;
                    background-image: linear-gradient(to bottom, $orange 0%, $orange 100%);
                }
            }
            .slider-handle {
                margin-left: -15px;
                &.custom {
                    @include vertical-align();
                    &:before {
                        content: "";
                        @include vertical-align();
                        display: block;
                        color: transparent;
                        line-height: 30px;
                        width: 25px;
                        height: 25px;
                        background-color: $gray-lighter;
                        border-radius: 50%;
                    }
                    &:hover:before {
                        box-shadow: 0 0 20px $orange;
                    }
                }
            }
        }
    }
}
