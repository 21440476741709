/* BLADE: source/_partials/landing/hero.blade.php */
/* SCSS:  source/_assets/sass/partials/modules/landing/_hero.scss */

.landing-background {
    background: url($images-path + '/header/header-background.jpg') scroll no-repeat top center transparent;
    background-size: cover;
    @include rem((
            margin-top: -64px,
            padding-top: 164px,
            padding-bottom: 100px
    ));
    @include media-breakpoint-down (md) {
        @include rem((
                margin-top: -104px,
                padding-top: 104px,
        ));
    }
    @include media-breakpoint-down (sm) {
        @include rem ((
                padding-bottom:25px
        ))
    }
}

.hero {
    position: relative;
    display: block;
    min-height: 460px;
    @include media-breakpoint-down (md) {
        min-height: 1px;
    }
    .hero__claim {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @include rem ((
                padding-left: 15px,
                padding-right: 60px,
        ));
        @include media-breakpoint-down (md) {
            text-align: left;
            position: relative;
            top: 0;
            transform: none;
            @include rem ((
                    padding-right: 15px,
                    padding-left: 15px,
                    padding-bottom: 15px,
            ));
        }
        h2 {
            font-weight: $font-weight-medium;
            padding-top: 20px;
            @include media-breakpoint-down (lg) {
                @include rem ((
                        font-size:26px,
                        line-height:30px,
                        padding-right: 15px,
                        padding-top: 15px,
                ));
            }
            @include media-breakpoint-down (md) {
                @include rem ((
                        font-size:22px,
                        line-height:26px,
                ));
            }
            &.top-text {
                @include media-breakpoint-down (xs) {
                    display: none;
                }
            }
            &.bottom-text {
                display: none;
                @include media-breakpoint-down (xs) {
                    display: inherit;
                }
            }
        }
    }

    &__form {
        position: relative;
        background-color: rgba($blue, 0.7);
        @include rem ((
                padding:40px
        ));
        @include media-breakpoint-down(sm) {
            @include rem ((
                    padding:20px
            ));
        }
        .form-group {
            height: 45px;
            position: relative;
        }
        form {
            width: 100%;
        }
        h3 {
            color: white;
            text-transform: uppercase;
            @include rem((
                    margin-bottom:40px
            ));
            @include media-breakpoint-down (md) {
                @include rem((
                        margin-bottom:0,
                ));
            }
            @include media-breakpoint-down (xs) {
                @include rem((
                        font-size:18px,
                        margin-bottom: 0,
                ));
            }
        }
        &--cta {
            p {
                text-align: center;
                @include rem ((margin-bottom:4px));
                a {
                    color: white;
                }
            }
        }
        &__radio {

            @include rem ((margin:20px 0,));

            @include media-breakpoint-down (xs) {
                text-align: center;
                margin: 0 0 10px 0;
            }
            position: relative;
            p {
                color: white;
                font-weight: $font-weight-regular;
                @include rem ((margin:3px 0,));
            }

            span {
                &.radio-labelContainer {
                    &:before {
                        border: 2px solid white !important;
                    }
                    span {
                        &.radio-label {
                            color: white !important;
                        }
                    }
                }
            }
        }
    }
}