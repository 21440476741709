/*
  Create margin/padding class. example space-m-y-5px
*/
$space_amounts: (0, 1, 2, 3, 4, 5, 6, 8, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 100, 150, 200, 250, 300, 400, 500);
@mixin generate-margin-padding-y-class() {
    @each $space in $space_amounts {
        .space-m-y-#{$space}px {
            margin-top: #{$space}px;
            margin-top: #{$space/16}rem;
            margin-bottom: #{$space}px;
            margin-bottom: #{$space/16}rem;
        }
        .space-p-y-#{$space}px {
            padding-top: #{$space}px;
            padding-top: #{$space/16}rem;
            padding-bottom: #{$space}px;
            padding-bottom: #{$space/16}rem;
        }
    }
}
@mixin generate-margin-padding-x-class() {
    @each $space in $space_amounts {
        .space-m-x-#{$space}px {
            margin-left: #{$space}px;
            margin-left: #{$space/16}rem;
            margin-right: #{$space}px;
            margin-right: #{$space/16}rem;
        }
        .space-p-x-#{$space}px {
            padding-left: #{$space}px;
            padding-left: #{$space/16}rem;
            padding-right: #{$space}px;
            padding-right: #{$space/16}rem;
        }
    }
}
@mixin generate-margin-padding-class($type) {
    @each $space in $space_amounts {
        .space-m-#{$type}-#{$space}px {
            margin-#{$type}: #{$space}px;
            margin-#{$type}: #{$space/16}rem;
        }
        .space-p-#{$type}-#{$space}px {
            padding-#{$type}: #{$space}px;
            padding-#{$type}: #{$space/16}rem;
        }
    }
}
