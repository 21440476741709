/* BLADE: source/_partials/forms/input-check.blade.php */
/* SCSS:  source/_assets/sass/partials/base/forms/_input-check.scss */
.form-check {
    padding-left: 15px;
}

.custom-checkbox {
    padding-left: 0;
    display: inline-block;

    .custom-control-indicator {
        border-radius: 0;
        border: 1px solid $gray-lighter;
        width: 25px;
        height: 25px;
        background: transparent;
        top: 0px;
        display: inline-block;
        float: left;
        transform: translateX(-50%);

        &:after {
            content: '';
            width: 14px;
            height: 8px;
            position: absolute;
            top: 5px;
            left: 5px;
            border: 3px solid $orange;
            border-top: none;
            border-right: none;
            background: transparent;
            opacity: 0;
            transform: rotate(-45deg);
        }
        &:hover::after {
            opacity: 0.3;
        }
    }

    .custom-control-input:active ~ .custom-control-indicator,
    .custom-control-input:checked ~ .custom-control-indicator,
    .custom-control-input:indeterminate ~ .custom-control-indicator {
        background-color: transparent;
    }
    .custom-control-input:checked ~ .custom-control-indicator {
        &:after {
            opacity: 1;
        }
    }

    .custom-control-description {
        @include media-breakpoint-down (sm) {
            @include rem ((font-size: 14px,));
        }
    }
}
