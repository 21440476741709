/* BLADE: source/_partials/premio/garanzie.blade.php */
/* SCSS:  source/_assets/sass/partials/modules/premio/_garanzie.scss */

.garanzie {
    background-image: url($images-path + '/background/garanzie.png');
    @include rem ((margin-bottom: 60px,));
    @include media-breakpoint-only (md) {
        @include rem ((margin-bottom: 30px,));
    }
    @include media-breakpoint-down (sm) {
        @include rem ((margin-bottom: 15px,));
    }
    &__inner {
        display: block;
        @include rem ((padding: 15px 0px,));
    }

    .btn-garanzie {
        display: inline-block;
        float: left;
        margin-right: 0;

        @include media-breakpoint-only (sm) {
            @include rem ((line-height:20px,));
        }
        @include media-breakpoint-only (xs) {
            @include rem ((font-size:13px))
        }
        a {
            @include rem ((line-height:32px,));

            @include media-breakpoint-only (sm) {
                @include rem ((font-size:12px,));
            }
            @include media-breakpoint-only (md) {
                @include rem ((font-size:14px,));
            }
            @include media-breakpoint-only (lg) {
                @include rem ((font-size:14px,));
            }

        }

        &--blue {
            &:before {
                content: " ";
                display: inline-block;
                float: left;
                vertical-align: middle;
                width: 24px;
                height: 24px;
                border: 1px solid $blue;
                background: $blue; /* For browsers that do not support gradients */
                background: -webkit-linear-gradient($blue, #1d3a83); /* For Safari 5.1 to 6.0 */
                background: -o-linear-gradient($blue, #1d3a83); /* For Opera 11.1 to 12.0 */
                background: -moz-linear-gradient($blue, #1d3a83); /* For Firefox 3.6 to 15 */
                background: linear-gradient($blue, #1d3a83); /* Standard syntax */
                @include rem ((margin-right:10px));

                @include media-breakpoint-down (sm) {
                    @include rem ((margin:5px 10px 15px 0));
                }
            }
        }
        &--grey {
            &:before {
                content: " ";
                display: inline-block;
                float: left;
                vertical-align: middle;
                width: 24px;
                height: 24px;
                border: 1px solid #d4d4d4;
                background: $gray-bg;
                background: -webkit-linear-gradient(#f8f8f8, #d3d3d3); /* For Safari 5.1 to 6.0 */
                background: -o-linear-gradient(#f8f8f8, #d3d3d3); /* For Opera 11.1 to 12.0 */
                background: -moz-linear-gradient(#f8f8f8, #d3d3d3); /* For Firefox 3.6 to 15 */
                background: linear-gradient(#f8f8f8, #d3d3d3); /* Standard syntax */
                @include rem ((margin-right:10px));

                @include media-breakpoint-down (sm) {
                    @include rem ((margin:5px 6px 15px 0));
                }
            }
        }
    }

}