/* BLADE: source/_partials/modals/premio/modal.blade.php */
/* SCSS:  source/_assets/sass/partials/modules/premio/_modal.scss */
.modal {
    text-align: center;
    padding-right: 0 !important;
    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -4px;
        @include media-breakpoint-down(sm) {
            height: auto;
            vertical-align: top;
        }
    }
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.modal-open {
    padding-right: 0 !important;
    overflow: hidden;
    z-index: 99999;
    .modal-lg {
        max-width: 750px;
    }
    .modal-dialog {

        @include media-breakpoint-down(sm) {
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
        }
        .modal-content {
            border-radius: 0;
            width: auto;
            margin: 0 auto;
            border: none;
            @include rem ((padding:24px));
            @include media-breakpoint-down(sm) {
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
            }
            .modal-header {
                border-bottom: none;
                @include rem ((
                        padding: 25px 15px 15px 15px,
                ));
                @include media-breakpoint-down (sm) {
                    @include rem((
                            padding: 50px 15px 15px 15px,
                    ));
                }
                .close {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    text-shadow: none;
                    opacity: 1;
                    z-index: 1;
                    span {
                        @include rem((font-size: 30px,));
                        color: $orange;
                        float: none;
                    }
                }
                .modal-title {
                    z-index: 1;
                    color: $blue;
                    text-align: center;
                    font-family: $font-family-base;
                    font-weight: $font-weight-medium;
                }
            }
            .modal-body {
                background-color: white;
                @include media-breakpoint-down (sm) {
                    @include rem((
                            padding: 15px 15px 15px 15px,
                    ));
                }
            }
        }
    }
    .modal__scelte {
        width: 80%;
        margin: 0 auto;
        @include media-breakpoint-down (xs) {
            text-align: center;
        }
        img {
            @include rem ((margin-top: 20px, margin-bottom:20px));
            @include media-breakpoint-down (xs) {
                float: none;
            }
        }
        p {
            &.arrow-sotto {
                box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.08);
                border-bottom: 1px solid $gray-form;
                @include rem((padding-bottom: 10px,));
                text-align: center;
                &:after {
                    position: absolute;
                    bottom: 6px;
                    left: 33%;
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-top: 10px solid $gray-form;
                    content: " ";
                    display: block;
                    @include media-breakpoint-down (xs) {
                        left: 48%;
                    }
                }
            }
            &.arrow-sinistra {
                box-shadow: 6px 0px 6px -6px rgba(0, 0, 0, 0.08);
                border-left: 1px solid $gray-form;
                @include rem((padding-left: 10px,));

                @include media-breakpoint-down (sm) {
                    padding-left: 0;
                    border-left: none;
                    margin-top: 0;
                }

                &:after {
                    position: absolute;
                    top: 25%;
                    left: 5px;
                    width: 0;
                    height: 0;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    border-right: 10px solid $gray-form;
                    content: " ";
                    display: block;
                    @include media-breakpoint-down (sm) {
                        display: none;
                    }
                }
            }
        }
    }
    .modal__selezionabili {
        width: 80%;
        margin: 0 auto;
        @include media-breakpoint-down (xs) {
            text-align: center;
        }

        img {
            @include rem ((margin-top: 20px, margin-bottom:40px));
            @include media-breakpoint-down (xs) {
                float: none;
            }
        }

        p {
            &.arrow-sotto {
                box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.08);
                border-bottom: 1px solid $gray-form;
                @include rem((padding-bottom: 10px,));
                text-align: center;
                &:after {
                    position: absolute;
                    bottom: 6px;
                    left: 48%;
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-top: 10px solid $gray-form;
                    content: " ";
                    display: block;
                }
            }
            &.arrow-sopra {
                box-shadow: 0 -6px 6px -6px rgba(0, 0, 0, 0.08);
                border-top: 1px solid $gray-form;
                @include rem((padding-top: 10px,));
                text-align: center;
                &:after {
                    position: absolute;
                    top: -10px;
                    left: 48%;
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid $gray-form;
                    content: " ";
                    display: block;
                }
            }
        }
    }
    #modalInfo01 {
        .modal-title {
            text-align: left;
            &:before {
                content: "\f05a";
                display: inline-block;
                font-family: FontAwesome;
                color: $blue;
                margin-right: 10px;
            }
        }
        .modal__incendio {
            .well {
                background-color: $gray-bg;
                @include rem ((padding:8px 10px));
                img {
                    width: 56px;
                    height: 56px;
                    float: left;
                }
                p {
                    margin: 0;
                    @include rem ((font-size:14px, line-height:16px, padding:0 0 0 40px));
                    @include media-breakpoint-down (sm) {
                        @include rem ((padding:0 0 0 60px));
                    }
                }
            }
            span {
                font-weight: $font-weight-bold;
            }
            h4 {
                font-family: $font-family-base;
                font-weight: $font-weight-medium;
                color: $blue;
            }
            .disclaimer {
                p {
                    margin-bottom: 0;
                    @include rem ((font-size:12px, line-height:16px))
                }
            }
            .radio {
                &__label {
                    font-family: $font-family-base;
                    font-weight: $font-weight-light;
                    text-transform: none;
                    color: $gray;
                }
                &__buttons {
                    span {
                        font-weight: $font-weight-regular;
                    }
                }
            }
            .info {
                color: $orange;
                font-family: FontAwesome;
                font-size: 16px;
                font-size: 1rem;
                position: relative;
                top: 0;
                right: -5px;
                width: 16px;
                height: 16px;
                z-index: 100;
            }
        }
    }
    #modalInfo08 {
        .modal-title {
            text-align: left;
            &:before {
                content: "\f05a";
                display: inline-block;
                font-family: FontAwesome;
                color: $blue;
                margin-right: 10px;
            }
        }
        .modal__rcterzi {
            .well {
                background-color: $gray-bg;
                @include rem ((padding:8px 10px));
                img {
                    width: 30px;
                    height: 30px;
                    float: left;
                }
                p {
                    margin: 0;
                    @include rem ((font-size:14px, line-height:16px, padding:0 0 0 40px));
                }
            }
            h4 {
                font-family: $font-family-base;
                font-weight: $font-weight-medium;
                color: $blue;
            }
            h6 {
                font-weight: $font-weight-light;
                color: $blue;
                @include rem ((margin-bottom:20px));
            }
            span {
                font-weight: $font-weight-bold;
            }
            .radio {
                &__label {
                    font-family: $font-family-base;
                    font-weight: $font-weight-medium;
                    @include rem ((font-size:14px));
                    p {
                        margin: 0;
                        @include rem ((font-size:12px,));
                        font-family: $font-family-base;
                        font-weight: $font-weight-regular;
                    }
                }
                &__buttons {
                    span {
                        font-weight: $font-weight-regular;
                    }
                }
            }

            .select-type {
                padding-top: 0;
                label {
                    display: none;
                }
            }
        }
    }
    #modalRicontatto {
        .modal-title {
            text-align: left;
            &:before {
                content: "\f05a";
                display: inline-block;
                font-family: FontAwesome;
                color: $blue;
                margin-right: 10px;
            }
        }
    }
    #modalGuida {
        #modalCarousel {
            .carousel-indicators {
                bottom: -20px;
                li {
                    background-color: $gray-light;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    cursor: pointer;
                    &.active {
                        background-color: $orange;
                    }
                }
            }
            .carousel-inner {
                min-height: 390px;
                .modal__guida {
                    h4 {
                        font-family: $font-family-base;
                        font-weight: $font-weight-medium;
                        color: $blue;
                        text-align: center;
                    }
                    &.slide-1 {
                        p {
                            &.arrow-sotto {
                                box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.08);
                                border-bottom: 1px solid $gray-form;
                                @include rem((padding-bottom: 10px,));
                                text-align: center;
                                &:after {
                                    position: absolute;
                                    bottom: 6px;
                                    left: 49%;
                                    width: 0;
                                    height: 0;
                                    border-left: 10px solid transparent;
                                    border-right: 10px solid transparent;
                                    border-top: 10px solid $gray-form;
                                    content: " ";
                                    display: block;
                                }
                            }
                            &.arrow-sopra {
                                box-shadow: 0 -6px 6px -6px rgba(0, 0, 0, 0.08);
                                border-top: 1px solid $gray-form;
                                @include rem((padding-top: 10px,));
                                text-align: center;
                                &:before {
                                    position: absolute;
                                    top: -9px;
                                    left: 49%;
                                    width: 0;
                                    height: 0;
                                    border-left: 10px solid transparent;
                                    border-right: 10px solid transparent;
                                    border-bottom: 10px solid $gray-form;
                                    content: " ";
                                    display: block;
                                }
                            }
                        }
                    }
                    &.slide-2 {
                        p {
                            &.arrow-sotto {
                                box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.08);
                                border-bottom: 1px solid $gray-form;
                                @include rem((padding-bottom: 10px,));
                                text-align: center;
                                &:after {
                                    position: absolute;
                                    bottom: 6px;
                                    left: 28%;
                                    width: 0;
                                    height: 0;
                                    border-left: 10px solid transparent;
                                    border-right: 10px solid transparent;
                                    border-top: 10px solid $gray-form;
                                    content: " ";
                                    display: block;
                                }
                            }
                            &.arrow-sinistra {
                                box-shadow: -6px 0px 6px -6px rgba(0, 0, 0, 0.08);
                                border-left: 1px solid $gray-form;
                                @include rem((padding-left: 10px, padding-right: 26px));

                                @include media-breakpoint-down (sm) {
                                    padding-left: 0;
                                    border-left: none;
                                    margin-top: 0;
                                }

                                &:after {
                                    position: absolute;
                                    top: 0;
                                    left: 5px;
                                    width: 0;
                                    height: 0;
                                    border-top: 10px solid transparent;
                                    border-bottom: 10px solid transparent;
                                    border-right: 10px solid $gray-form;
                                    content: " ";
                                    display: block;
                                    @include media-breakpoint-down (sm) {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .carousel-item {
                transition: transform 1s ease, opacity .5s ease-out;
            }
            .carousel-control-prev, .carousel-control-next {
                color: $orange;
                opacity: 1;
                width: 10px;
            }
        }
    }
}
