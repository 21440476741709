/* BLADE: source/_partials/premio/dati.blade.php */
/* SCSS:  source/_assets/sass/partials/modules/premio/_dati.scss */

.dati {
    border-top: 1px solid $gray-bg;
    @include rem ((padding:15px 0 0 0));
    background-color: #f8f8f8;
    box-shadow: 0 20px 16px -16px rgba(0, 0, 0, 0.08);
    z-index: 10;
    @include media-breakpoint-down (xs) {
        @include rem((
                padding: 0,
        ));
    }
    .panel-default {
        .collapsing {
            transition: all 0.5s ease-in-out;
        }
    }
    .panel-heading {
        background-color: #f8f8f8;
        @include media-breakpoint-down (xs) {
            @include rem ((margin-top:15px));
        }
        .panel-title {
            @include rem ((padding-left: 0px, padding-right: 0px));
            a {
                color: $orange;
                @include rem ((font-size:21px));
                @include media-breakpoint-down (lg) {
                    @include rem ((padding-right: 30px));
                }
                @include media-breakpoint-down (md) {
                    @include rem ((padding-right: 30px));
                }
                @include media-breakpoint-down (sm) {
                    @include rem ((font-size:16px, line-height:18px, padding-right: 30px));
                }
                @include media-breakpoint-down (xl) {
                    @include rem ((padding-right: 32px));
                }

            }
        }
        @include media-breakpoint-down (sm) {
            .more-less {
                background-color: $orange;
                color: white;
                @include rem((padding:8px 10px))
            }
        }
    }
    .panel-body {
        .accordion-dati {

            @include media-breakpoint-up (md) {
                @include rem((padding-right: 30px,))
            }
            .info {
                color: $orange;
            }
            .salva-link {
                text-align: right;
                color: $gray;
                font-weight: $font-weight-medium;
                margin-bottom: 0;

                a {
                    color: $orange;
                    @include rem ((margin-left: 10px,));
                }
            }
            .accordion-content {
                display: none;
                &--active {
                    display: block;
                }
                .form-group {
                    margin-bottom: 20px;
                }
            }
            .form-group {
                @include rem ((margin-bottom:20px))
            }
        }
    }
}