/* BLADE: source/_partials/landing/base/footer.blade.php */
/* SCSS:  source/_assets/sass/partials/base/_footer.scss */

.footer {
  background-color: $gray-bg;
  @include rem ((margin-top: 30px, padding: 30px 0px,));

  @include media-breakpoint-down(md){
    @include rem ((padding-bottom: $sidebar-mobile-bar-height + 50px,));
    &--landing, &--thankyou{ @include rem ((padding-bottom: 30px,)); }
  }

  &--thankyou{ @include rem ((margin-top: 0px,  padding-bottom: 30px )); }

  nav {
    ul {
      list-style-type: none;
      @include rem ((margin: 0, padding: 0, ));
    }
    li { float: left; }
  }

  &--sx {
    nav {
      display: table;
      margin: auto;
      @include media-breakpoint-up(sm) { float: left; }
    }
    p {
      text-align: center;
      @include media-breakpoint-up(sm) { text-align: left; }
    }
  }

  &--dx {
    nav {
      display: table;
      margin: auto;
      @include media-breakpoint-up(sm) { float: right; }
    }
    p {
      text-align: center;
      @include media-breakpoint-up(sm) { text-align: right; }
    }
  }

  &__logo {
    @include media-breakpoint-up(sm) {
      img { width: 44px; height: 44px; }
    }
  }

  &__social {

    nav { padding-top: 23px; }

    &__item {
      margin-left: 30px;
      @include media-breakpoint-up(sm) { margin-left: 15px; }
      &:first-child { margin-left: 0px; }

      a {
        font-size: 30px; line-height: 30px;
        @include media-breakpoint-up(sm) { font-size: 20px; line-height: 20px; }
      }

    }
  }

  &__text {
    font-size: 13px; margin: 30px 0px; color: $gray-dark; text-align: justify;
    @include media-breakpoint-up(md) { margin-top: 10px; }
  }

  &__bottom {

    p, a, li {
      font-size: 13px;
      line-height: 13px;
      font-weight: $font-weight-light;
      color: $gray-light;
    }

    .footer--sx {

      li {
        padding-right: 10px;
        margin-right: 10px;
        &:last-child { border: 0px; margin: 0px; padding: 0px; }
        border-right: 1px solid $gray-light;
        a {
          &:hover { color: $gray; }
        }
      }
    }

    .footer--dx {
      p {
        margin: 20px 0px 0px;
        @include media-breakpoint-up(sm) { margin: 0px; }
      }
    }

  }
}
