/* BLADE: source/_partials/landing/ruota-container.blade.php */
/* SCSS:  source/_assets/sass/partials/modules/landing/_ruota-container.scss */
/* Called: source/_assets/partials/_landing-page.scss - row: 5 */

$wheelHeight: 600px;
$wheelWidth: 600px;
$bgRowHeight: 92px;
.wrapperLand {
    border-top: 2px solid white;
    margin-bottom:20px;
    @include media-breakpoint-up(lg) {
        min-height: $wheelHeight;
    }
    &__inner {
        display: block;
        width: 100%;
        .sectorLand {
            display: block;
            width: 100%;
            padding-top: 20px;
            background-color: $gray-bg;
            @include media-breakpoint-up(lg) {
            }
            position: relative;
            .container {
                @include media-breakpoint-down(xs) {
                }
            }
        }
        .bodyBox {
            position: absolute;
            left: 30px;
            top: 0;
            z-index: 1;
        }
        @import "ruota";
        @import "ruota-mobile";
        .coldx {
            @include media-breakpoint-up(lg) {
                padding-left: $wheelWidth + 40px;
            }
            display: block;
            float: left;
            width: 100%;
            @include rem((padding-top: 15px));
            &__inner {
                display: block;
                width: 90%;
                margin: 0 auto;
                position: relative;
                @include media-breakpoint-only(lg) {
                    width: 100%;
                }
                .container-fluid {
                    @include media-breakpoint-down(xs) {
                        padding: 0;
                    }
                }
                h1 {
                    position: relative;
                    text-align: center;
                    background-color: transparent;
                    margin: 0;
                    padding: 0;
                    height: $bgRowHeight;
                    line-height: $bgRowHeight;
                    color: white;
                    text-transform: uppercase;
                    @include rem((
                            font-size: 38px !important
                    ));
                    span.heavy {
                    }
                    &:after {
                        position: absolute;
                        left: 50%;
                        bottom: -25px;
                        display: block;
                        content: "";
                        width: 0;
                        height: 0;
                        border-top: 25px solid rgba(#003895, 0.75);
                        border-left: 26px solid transparent;
                        border-right: 26px solid transparent;
                        transform: translateX(-50%);
                        -webkit-transform: translateX(-50%);
                        -ms-transform: translateX(-50%);
                        z-index: 1;
                    }
                    span.vertical_align {
                        @include media-breakpoint-only(lg) {
                            position: absolute;
                            display: block;
                            left: 50%;
                            top: 50%;
                            width: 100%;
                            transform: translate(-50%, -50%);
                            -ms-transform: translate(-50%, -50%);
                            -o-transform: translate(-50%, -50%);
                            -moz-transform: translate(-50%, -50%);
                            -webkit-transform: translate(-50%, -50%);
                            @include rem((line-height: 34px));
                        }
                        @include media-breakpoint-down(xs) {
                            position: absolute;
                            display: block;
                            left: 50%;
                            top: 50%;
                            width: 100%;
                            transform: translate(-50%, -50%);
                            -ms-transform: translate(-50%, -50%);
                            -o-transform: translate(-50%, -50%);
                            -moz-transform: translate(-50%, -50%);
                            -webkit-transform: translate(-50%, -50%);
                            @include rem((line-height: 24px));
                        }
                    }
                    @include rem((font-size: 30px));
                    @include media-breakpoint-only(lg) {
                        @include rem((font-size: 30px));
                    }
                    @include media-breakpoint-up(xxl) {
                        @include rem((font-size: 40px));
                    }
                    @include media-breakpoint-down(sm) {
                        @include rem((font-size: 30px));
                    }
                    @include media-breakpoint-down(xs) {
                        @include rem((font-size: 22px, line-height: 24px));
                    }
                }
                .vantaggi {
                    list-style: none;
                    margin: 0;
                    padding: 40px 0;
                    @include media-breakpoint-down(lg) {
                        padding: 20px 0;
                    }
                    li {
                        position: relative;
                        list-style: none;
                        margin: 0;
                        @include media-breakpoint-only(xl) {
                            font-weight: $font-weight-light;
                            font-size: $font-size-sm;
                            line-height: $font-size-sm + 0.25rem;
                        }
                        @include media-breakpoint-up(xl) {
                            padding: 75px 15px 0 15px;
                            float: left;
                            width: 25%;
                            text-align: center;
                        }
                        @include media-breakpoint-down(lg) {
                            font-weight: $font-weight-light;
                            font-size: $font-size-sm;
                            line-height: $font-size-sm + 0.25rem;
                            padding: 15px 0 15px 55px;
                            width: 100%;
                            text-align: left;
                            min-height: 56px;
                        }
                        &:after {
                            content: '';
                            position: absolute;
                            background-color: transparent;
                            background-position: center center;
                            background-repeat: no-repeat;
                            @include media-breakpoint-up(xl) {
                                width: 60px;
                                height: 60px;
                                top: 0;
                                left: 50%;
                                transform: translateX(-50%);
                                -ms-transform: translateX(-50%);
                                -o-transform: translateX(-50%);
                                -moz-transform: translateX(-50%);
                                -webkit-transform: translateX(-50%);
                            }
                            @include media-breakpoint-down(lg) {
                                width: 46px;
                                height: 46px;
                                top: 50%;
                                left: 0;
                                transform: translateY(-50%);
                                -ms-transform: translateY(-50%);
                                -o-transform: translateY(-50%);
                                -moz-transform: translateY(-50%);
                                -webkit-transform: translateY(-50%);
                            }
                        }
                    }
                    &__tutela:after {
                        background-image: url($images-path + '/vantaggi/icona-tutela.svg');
                    }
                    &__assistenza:after {
                        background-image: url($images-path + '/vantaggi/icona-assistenza.svg');
                    }
                    &__confido:after {
                        background-image: url($images-path + '/vantaggi/icona-confido.svg');
                    }
                    &__tecnologia:after {
                        background-image: url($images-path + '/vantaggi/icona-tecnologia.svg');
                    }
                }
                .sapeviDesk {
                    overflow: hidden;
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                        background-color: white;
                        position: relative;
                        &:after {
                            content: "";
                            position: absolute;
                            right: -30%;
                            top:0;
                            display: block;
                            width: 30%;
                            height: 100%;
                            background-color: white;
                        }
                    }
                    @include media-breakpoint-up(lg) {
                        position: absolute;
                        right: -30px;
                        bottom: -200px;
                    }
                    @include media-breakpoint-only(lg) {
                        bottom: -170px;
                        font-size: $font-size-sm;
                        line-height: $font-size-sm + 0.25rem;
                    }
                    width: calc(100% + 60px);
                    .slick-dots li {
                        position: relative;
                        display: inline-block;
                        height: 1rem;
                        width: 1rem;
                        @include rem((margin: 0 5px));
                        padding: 0;
                        cursor: pointer;
                        button {
                            border: 0;
                            background: transparent;
                            display: block;
                            width: 1rem;
                            height: 1rem;
                            outline: none;
                            line-height: 0;
                            font-size: 0;
                            color: transparent;
                            @include rem((padding: 5px));
                            cursor: pointer;
                            &:before {
                                width: 1rem;
                                height: 1rem;
                                font-size: 0.75rem;
                                line-height: 1rem;
                                color: $gray-light;;

                            }
                        }
                        &.slick-active {
                            button:before {
                                color: $orange;
                            }
                        }
                    }
                }
            }
        }
        .sapeviMob {
            overflow: hidden;
            padding-top: 20px;
            @include media-breakpoint-down(md) {
                width: 100%;
                background-color: white;
                position: relative;
            }
            @include media-breakpoint-up(lg) {
                display: none;
            }
            width: calc(100% + 60px);
            .slick-dots li {
                position: relative;
                display: inline-block;
                height: 1rem;
                width: 1rem;
                @include rem((margin: 0 5px));
                padding: 0;
                cursor: pointer;
                button {
                    border: 0;
                    background: transparent;
                    display: block;
                    width: 1rem;
                    height: 1rem;
                    outline: none;
                    line-height: 0;
                    font-size: 0;
                    color: transparent;
                    @include rem((padding: 5px));
                    cursor: pointer;
                    &:before {
                        width: 1rem;
                        height: 1rem;
                        font-size: 0.75rem;
                        line-height: 1rem;
                        color: $gray-light;;

                    }
                }
                &.slick-active {
                    button:before {
                        color: $orange;
                    }
                }
            }
        }
    }
}