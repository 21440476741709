/* BLADE: source/_partials/premio/mappa.blade.php */
/* SCSS:  source/_assets/sass/partials/modules/premio/_mappa.scss */

.mappa {
    display: none;
    @include rem((margin-top: 90px,));

    &--active {
        display: block;
    }

    &__info {
        h3 {
            color: $blue;
            text-align: center;
            @include rem((margin-bottom: 0px,));
        }
        p {
            text-align: center;
            @include rem((margin-bottom: 5px,));

            strong {
                font-weight: $font-weight-bold;
            }
        }
    }

    &__divider {
        display: block;
        width: 30%;
        margin: 35px auto;
    }

    &__cartina {
        @include rem((margin: 20px 0,));
    }

    &__form {
        @include rem((margin: 30px,));

        .mandatory {
            text-align: left;
            font-weight: $font-weight-light;
            @include rem((font-size: 13px,));

            a {
                color: $gray;
                font-weight: $font-weight-light;
                text-decoration: underline;
            }
        }

        &__cta {
            text-align: center;
            p {
                text-align: center;
            }
            i {
                &.fa {
                    display: none;
                }
            }
        }
    }

}