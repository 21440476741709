/* BLADE: source/dati.blade.php */
/* SCSS:  source/_assets/sass/partials/_dati.scss */
/* Called: source/_assets/partials/_styles.scss */


.inserisci-dati {
    @include rem ((margin-top:2px));
    border-top: 1px solid $gray-bg;
    &__inner {
        @include rem ((padding: 0px,));
        @include media-breakpoint-down (sm) {
            @include rem ((padding: 0px 5px,));
        }
        .radio__label {
            @include rem ((margin-right:25px))
        }
    }
    .mandatory {
        padding-top: 5px;
        text-align: left;
        font-weight: $font-weight-light;
        @include rem((font-size: 13px, margin-right:15px));
    }
    .title-icon {
        font-weight: $font-weight-medium;
        border-bottom: 1px solid $gray-form;
        color: $blue;
        position: relative;
        @include rem ((margin:60px 0px 20px, font-size: 16px, line-height: 35px, padding-left: 40px,));
        @include media-breakpoint-down (sm) {
            @include rem ((font-size: 14px, line-height: 25px, padding-left: 30px,));
        }

        .icon {
            display: block;
            width: 35px;
            height: 35px;
            position: absolute;
            top: -5px;
            left: 0px;

            @include media-breakpoint-down (sm) {
                width: 25px;
                height: 25px;
            }

            &--casa {
                background: url($images-path + '/sezioni/casa.svg') bottom left no-repeat;
            }
            &--carrello {
                background: url($images-path + '/sezioni/carrello.svg') bottom left no-repeat;
            }
            &--user {
                background: url($images-path + '/sezioni/user.svg') bottom left no-repeat;
            }
            &--agente {
                background: url($images-path + '/sezioni/agente.svg') bottom left no-repeat;
            }
            &--documenti {
                background: url($images-path + '/sezioni/documenti.svg') bottom left no-repeat;
            }
            &--informative {
                background: url($images-path + '/sezioni/informative.svg') bottom left no-repeat;
            }
        }

        @extend .clearfix;
    }
    .container:first-child .title-icon {
        @include rem ((margin-top:25px,));
    }
}
#Prosegui {
    padding: 10px 40px ;
}