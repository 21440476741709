/* SCSS:  source/_assets/sass/partials/modules/premio/_karte-slider.scss */

.karte__carousel {
    .slick-slide, .slick-active, .slick-current {
        width: auto !important;
    }
    .slick-track {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        display: flex;
    }
    .slick-arrow {
        top: auto;
        bottom: 0;
        transform: translate(0, 58%);
        height: 32px;
        width: 32px;
        cursor: pointer;
        background: $blue-dark;
        color: white;
        z-index: 998;
        border-radius: 4px;
    }
    .slick-prev {
        left: 0;
        &:before {
            font-family: "FontAwesome", sans-serif;
            content: "\f053";
            font-size: 16px;
            opacity: 1;
        }
    }
    .slick-next {
        right: 0;
        &:before {
            font-family: "FontAwesome", sans-serif;
            content: "\f054";
            font-size: 16px;
            opacity: 1;
        }
    }
    .slick-disabled {
        display: none!important;
        pointer-events:none;
    }
    ul {
        &.slick-dots {
            bottom: -15px;
            li {
                margin: 0 10px;
                &.slick-active {
                    button {
                        &:before {
                            color: $blue-dark;
                            opacity: 1;
                        }
                    }
                }
                button {
                    &:before {
                        font-size: 30px;
                        color: $gray-light;
                        opacity: 1;
                    }
                }
            }
        }
    }
}