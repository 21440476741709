/* SCSS  : resources/assets/sass/partials/base/form/_button.scss */
/* BLADE : resources/views/partials/forms/button.blade.php */
/* CALLED: resources/assets/sass/partials/base/_core.scss */

.btn {
    min-height: 50px;
    line-height: 28px;
    font-weight: $font-weight-medium;
    @include rem ((padding: 10px 20px,));
    @include media-breakpoint-down(xs) {
        white-space: inherit;
        position: relative;
        min-width: 90%;
        span {
            position: absolute;
            text-align: center;
            left: 50%;
            top: 50%;
            width: 100%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            line-height: $font-size-base;
        }
    }

    &-green {
        background-color: $green;
        border: 2px solid $green;
        color: white;
        &:hover {
            background-color: darken($green, 10%);
            border: 2px solid darken($green, 10%);
            color: white;
        }
        &:visited, &:focus {
            color: white;
        }
    }
    &-green-bordered {
        background-color: transparent;
        border: 2px solid $green;
        color: $green;
        box-sizing: border-box;
        &:hover {
            border: 2px solid darken($green, 10%);
            span {
                color: darken($green, 10%);
            }
        }
        &:visited {
            color: $green;
        }
        &:focus {
            color: darken($green, 10%);
        }
    }
    &-orange {
        background-color: $orange;
        border: 2px solid $orange;
        color: white;
        &:hover {
            background-color: darken($orange, 10%);
            border: 2px solid darken($orange, 10%);
            color: white;
        }
        &:visited, &:focus {
            color: white;
        }
    }
    &-orange-bordered {
        background-color: transparent;
        border: 2px solid $orange;
        color: $orange;
        box-sizing: border-box;
        &:hover {
            border: 2px solid darken($orange, 10%);
            span {
                color: darken($orange, 10%);
            }
        }
        &:visited {
            color: $orange;
        }
        &:focus {
            color: darken($orange, 10%);
        }
        a {
            color: $orange;
        }
    }
}