/* BLADE: source/_partials/forms/input-text.blade.php */
/* SCSS:  source/_assets/sass/partials/base/forms/_input-text.scss */

.form-group {
    &.inputText {
        position: relative;
        .form-control {
            margin: 0;
        }
        label {
            color: $gray-light;
            @include rem ((font-size: 16px, line-height:45px));
            @include media-breakpoint-down (lg) { @include rem((font-size: 14px)); }
            position: absolute;
            pointer-events: none;
            left: 10px;
            top: 0;
            transition: 0.2s ease all;
            -moz-transition: 0.2s ease all;
            -webkit-transition: 0.2s ease all;
        }
        input {
            @include rem ((font-size: 16px, padding: 12px 10px 0 10px));
            @include media-breakpoint-down (lg) { @include rem((font-size: 14px)); }
            border: 1px solid $gray-form;
            &:focus {
                ~ label {
                    top: 5px;
                    @include rem ((font-size: 12px, line-height:12px));
                    color: $orange;
                    font-weight: $font-weight-medium;
                    text-transform: uppercase;
                }
            }
            &:valid {
                ~ label {
                    top: 5px;
                    @include rem ((font-size: 12px, line-height:12px));
                    color: $orange;
                    font-weight: $font-weight-medium;
                    text-transform: uppercase;
                }
            }
        }

        &.has-danger {
            input { border: 1px solid $red; }
        }
    }
}

.info {
    font-family: FontAwesome;
    @include rem ((font-size: 16px));
    @include media-breakpoint-down (lg) { @include rem((font-size: 14px)); }
    color: white;
    position: absolute;
    top: 0;
    right: -5px;
    width: 16px;
    height: 16px;
    z-index: 100;

    @include media-breakpoint-down(sm) {
        @include rem ((font-size: 24px));
        color: $orange;
        top: 10px;
        right: 28px;
    }

    &:hover {
        cursor: pointer;
    }
}

.popover {
    font-family: $font-family-base;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    @include media-breakpoint-down (sm) {
        width: 200px !important;
    }

    .popover-inner {
        background-color: $gray-bg;
        color: $gray-dark;
        a {
            color: $orange;
        }
    }
}